var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "convert-coupon-modal" }, [
    !_vm.existsCoupon
      ? _c("div", { staticClass: "convert-coupon-modal__top-area" }, [
          _c(
            "div",
            { staticClass: "convert-coupon-modal__thumbnail-wrapper" },
            [
              _c("img", {
                staticClass: "convert-coupon-modal__thumbnail",
                attrs: { src: _vm.lotteryResultPrize.thumbnail_url },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "convert-coupon-modal__descriptions" }, [
            _c("p", { staticClass: "convert-coupon-modal__prize-title" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.lotteryResultPrize.prize_title) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "convert-coupon-modal__convert-coupon-question" },
              [_vm._v("\n        を下記に変換しますか？\n      ")]
            ),
          ]),
        ])
      : _c(
          "p",
          { staticClass: "convert-coupon-modal__converted-coupon-success" },
          [_vm._v("\n    変換しました！\n  ")]
        ),
    _vm._v(" "),
    _c("div", { staticClass: "convert-coupon-modal__bottom-area" }, [
      !_vm.existsCoupon
        ? _c(
            "div",
            { staticClass: "convert-coupon-modal__coupon-area-wrapper" },
            [
              _c("div", { staticClass: "convert-coupon-modal__coupon-area" }, [
                _c(
                  "div",
                  { staticClass: "convert-coupon-modal__coupon-name-area" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "convert-coupon-modal__coupon-image-wrapper",
                      },
                      [
                        _c("img", {
                          staticClass: "convert-coupon-modal__coupon-image",
                          attrs: { src: _vm.iconImage },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "p",
                      { staticClass: "convert-coupon-modal__coupon-name" },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.couponName(
                                _vm.lotteryResultPrize
                                  .convertable_coupon_discount_name
                              )
                            ) +
                            "\n          "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.lotteryResultPrize.convertable_coupon_condition_price
                  ? _c(
                      "p",
                      {
                        staticClass:
                          "convert-coupon-modal__coupon-condition-price",
                      },
                      [
                        _vm._v(
                          "\n          (" +
                            _vm._s(_vm.couponConditionPrice) +
                            ")\n        "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "convert-coupon-modal__coupon-expired-at" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.couponExpiredAt) +
                        "\n        "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.existsConvertablePoints
                ? _c(
                    "div",
                    { staticClass: "convert-coupon-modal__coupon-area" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "convert-coupon-modal__coupon-name-area",
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "p",
                            {
                              staticClass: "convert-coupon-modal__coupon-name",
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.format(
                                      _vm.lotteryResultPrize.convertable_points
                                    )
                                  ) +
                                  "\n            ポイント\n          "
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass:
                            "convert-coupon-modal__coupon-expired-at",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.pointsExpiredAt) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass: "convert-coupon-modal__coupon-convert-attention",
                },
                [_vm._v("\n        ※一度交換すると元に戻せません。\n      ")]
              ),
              _vm._v(" "),
              _vm.isRare
                ? _c(
                    "p",
                    {
                      staticClass:
                        "convert-coupon-modal__coupon-convert-rare-attention",
                    },
                    [
                      _vm._v(
                        "\n        貴重なカードですが本当に交換しても良いですか？\n      "
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "convert-coupon-modal__coupon-area convert-coupon-modal__coupon-area--converted",
            },
            [
              _c(
                "div",
                { staticClass: "convert-coupon-modal__coupon-name-area" },
                [
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "convert-coupon-modal__coupon-name" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.couponName(_vm.coupon.name)) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "convert-coupon-modal__coupon-condition-price" },
                [
                  _vm._v(
                    "\n        (" +
                      _vm._s(_vm.convertedCouponConditionPrice) +
                      ")\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                { staticClass: "convert-coupon-modal__coupon-expired-at" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.convertedCouponExpiredAt) +
                      "\n      "
                  ),
                ]
              ),
            ]
          ),
      _vm._v(" "),
      _vm.existsPoints
        ? _c(
            "div",
            {
              staticClass:
                "convert-coupon-modal__coupon-area convert-coupon-modal__coupon-area--converted",
            },
            [
              _c(
                "div",
                { staticClass: "convert-coupon-modal__coupon-name-area" },
                [
                  _vm._m(2),
                  _vm._v(" "),
                  _c(
                    "p",
                    { staticClass: "convert-coupon-modal__coupon-name" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.format(_vm.point_detail.points)) +
                          "\n          ポイント\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "p",
                {
                  staticClass:
                    "convert-coupon-modal__converted-coupon-description",
                },
                [
                  _vm._v(
                    "\n        所有ポイント:\n        " +
                      _vm._s(_vm.format(_vm.personal.points)) +
                      "\n        ポイント\n      "
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _vm._v(" "),
    !_vm.existsCoupon
      ? _c("div", { staticClass: "convert-coupon-modal__button-area" }, [
          _c(
            "button",
            {
              staticClass:
                "convert-coupon-modal__cancel-button btn btn--small btn--ghost-primary",
              on: { click: _vm.onClickCancelButton },
            },
            [_vm._v("\n      やめておく\n    ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: [
                "convert-coupon-modal__convert-coupon-button btn btn--small",
                _vm.isConvertedCoupon ? "btn--disabled" : "btn--primary-light",
              ],
              attrs: { disabled: _vm.isConvertedCoupon },
              on: {
                click: function ($event) {
                  _vm.changeCouponStatus()
                  _vm.onClickConvertCouponButton(_vm.lotteryResultPrize)
                },
              },
            },
            [_vm._v("\n      変換する\n    ")]
          ),
        ])
      : _c("div", { staticClass: "convert-coupon-modal__button-area" }, [
          _c(
            "button",
            {
              staticClass: "convert-coupon-modal__close-button btn",
              on: { click: _vm.onClickCancelButton },
            },
            [_vm._v("\n      閉じる\n    ")]
          ),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "convert-coupon-modal__points-icon-wrapper" },
      [_c("i", { staticClass: "fs-xl mr-s jirafficon jirafficon-point" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "convert-coupon-modal__converted-icon-wrapper" },
      [
        _c("i", {
          staticClass: "fs-3xl mr-xs jirafficon jirafficon-check-circle-filled",
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "div",
      { staticClass: "convert-coupon-modal__converted-icon-wrapper" },
      [
        _c("i", {
          staticClass: "fs-3xl mr-xs jirafficon jirafficon-check-circle-filled",
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }