import { Controller } from 'stimulus'
import { getGuide } from '~/api/guides/show'
import showModal from '~/core/showModal'

export default class extends Controller {
  show(): void {
    const slug = this.data.get('slug')
    if (!slug) {
      throw new Error('slugは必須です')
    }
    this.showGuideModal(slug)
  }

  async showGuideModal(slug: string): Promise<void> {
    const response = await getGuide(slug)
    const { guide } = response.data
    const el = document.createElement('div')
    el.classList.add('editable-content')
    el.innerHTML = `
      <h1 class="heading heading--1 m-m">${guide.title}</h1>
      <section class="pl-m pr-m">
        ${guide.body}
      </section>
    `
    showModal(el)
  }
}
