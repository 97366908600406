
import Vue from 'vue'

export default Vue.extend({
  methods: {
    submit(e: Event): void {
      this.$emit('submit', e)
    },
  },
})
