
import Vue from 'vue'
import couponImage from 'images/oripa/icon_coupon.png'
import selectImage from 'images/oripa/icon_select.png'
import numberFormattable from '~/components/mixins/numberFormattable'

interface Data {
  isConvertedCoupon: boolean
}

interface Methods {
  couponName(name: string): string
  changeCouponStatus(): void
}

interface Computed {
  iconImage: string
  couponConditionPrice: string
  couponExpiredAt: string
  existsCoupon: boolean
  existsConvertablePoints: boolean
  convertedCouponExpiredAt: string
  convertedCouponConditionPrice: string
  pointsExpiredAt: string
  existsPoints: boolean
  isRare: boolean
}

interface Props {
  lotteryResultPrize: Model.LotteryResultPrize
  coupon: Model.UserPossessedCoupon
  point_detail: Model.PointDetail
  personal: Model.Personal
  onClickCancelButton(): void
  onClickConvertCouponButton(lotteryResultPrize: Model.LotteryResultPrize): void
}

export default Vue.extend<Data, Methods, Computed, Props>({
  mixins: [numberFormattable],
  data() {
    return {
      isConvertedCoupon: false,
    }
  },
  props: {
    lotteryResultPrize: {
      type: Object,
      required: true,
    },
    coupon: {
      type: Object,
    },
    point_detail: {
      type: Object,
    },
    personal: {
      type: Object,
    },
    onClickCancelButton: {
      type: Function,
      required: true,
    },
    onClickConvertCouponButton: {
      type: Function,
      required: true,
    },
  },
  methods: {
    couponName(name: string): string {
      if (!name) {
        return ''
      }

      return `${name}offクーポン`
    },
    changeCouponStatus(): void {
      this.isConvertedCoupon = true
    },
  },
  filters: {
    yenFormat(price: number): string {
      return `¥${price.toLocaleString()}`
    },
  },
  computed: {
    iconImage(): string {
      if (this.existsCoupon) {
        return selectImage
      }

      return couponImage
    },
    couponConditionPrice(): string {
      if (!this.lotteryResultPrize.convertable_coupon_condition_price) {
        return '対象商品価格: なし'
      }

      return `対象商品価格: ¥${this.lotteryResultPrize.convertable_coupon_condition_price.toLocaleString()}以上`
    },
    couponExpiredAt(): string {
      if (!this.lotteryResultPrize.convertable_coupon_expired_at) {
        return '有効期限: なし'
      }

      return `有効期限: ${this.lotteryResultPrize.convertable_coupon_expired_at}`
    },
    existsCoupon(): boolean {
      if (this.coupon) {
        return true
      }

      return false
    },
    convertedCouponExpiredAt(): string {
      return `有効期限: ${this.coupon?.expired_at || 'なし'}`
    },
    convertedCouponConditionPrice(): string {
      if (!this.coupon?.conditions) {
        return '対象商品価格: なし'
      }

      return this.coupon.conditions
        .map((condition) => {
          return `${condition.label}: ${condition.value}`
        })
        .join(', ')
    },
    existsConvertablePoints(): boolean {
      return !!this.lotteryResultPrize.convertable_points
    },
    pointsExpiredAt(): string {
      if (!this.lotteryResultPrize.convertable_points_expire_at) {
        return '有効期限: なし'
      }

      return `有効期限: ${this.lotteryResultPrize.convertable_points_expire_at}`
    },
    existsPoints(): boolean {
      return !!this.point_detail?.points
    },
    isRare(): boolean {
      return (
        this.lotteryResultPrize.grade === 'first_winning' ||
        this.lotteryResultPrize.grade === 'second_winning'
      )
    },
  },
})
