var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "snackbar" }, [
    _c(
      "ul",
      [
        _c(
          "transition-group",
          { attrs: { name: "snack" } },
          _vm._l(_vm.displayedSnacks, function (snack) {
            return _c(
              "li",
              {
                key: snack.id,
                class: _vm.snackClass(snack),
                attrs: { "aria-label": "Snack Bar" },
              },
              [
                snack.icon
                  ? _c("i", {
                      class: `icon jirafficon jirafficon-${snack.icon}`,
                      attrs: { "aria-label": snack.icon },
                    })
                  : _vm._e(),
                _vm._v("\n        " + _vm._s(snack.title) + "\n        "),
                _c("i", {
                  staticClass: "close-icon jirafficon jirafficon-close",
                  attrs: { "aria-label": "閉じる" },
                  on: { click: _vm.removeSnack },
                }),
              ]
            )
          }),
          0
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }