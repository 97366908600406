import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'

export const oripaLotteryBodyTemplate = (
  id: string
): Promise<
  AxiosResponse<Api.Administrators.OripaLotteryBodyTemplate.Response>
> => {
  return axios.get(`/administration/oripa/lottery_body_templates/${id}/body`)
}
