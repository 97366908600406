var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    { staticClass: "form__label" },
    [
      _vm._t("default"),
      _vm._v(" "),
      _vm.required
        ? _c("span", { staticClass: "form__required" }, [_vm._v(" ※必須 ")])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }