import { Controller } from 'stimulus'

export default class extends Controller {
  static targets: string[] = ['searchModal', 'closeBtn']

  searchModalTarget?: HTMLElement

  closeBtnTarget?: HTMLElement

  showSearchModal(): void {
    document.body.style.overflowY = 'hidden'
    this.searchModalTarget?.classList?.add('modal--shown')
    this.searchModalTarget?.classList?.remove('modal--hidden')
  }

  closeSearchModal(): void {
    document.body.style.removeProperty('overflow-y')
    this.searchModalTarget?.classList?.remove('modal--shown')
    this.searchModalTarget?.classList?.add('modal--hidden')
  }
}
