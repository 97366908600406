
import Vue from 'vue'
import formable from '~/components/mixins/formable'
import SearchableSelectBox from '~/components/molecules/form/SearchableSelectBox.vue'

interface Option {
  id: number | string
  name: string
}

interface Data {
  selected: Option[]
  filterOptions: Option[]
}

interface Methods {
  handleChangeStatuses(options: Option[]): void
  onSearchOption(search: string, loading: (arg: boolean) => void): void
}

interface Props {
  options: Option[]
  selectedOption: Option[]
  multiple: boolean
  name: string
  label: string
}

export default Vue.extend<Data, Methods, {}, Props>({
  mixins: [formable],
  components: {
    SearchableSelectBox,
  },
  data() {
    return {
      selected: [],
      filterOptions: [],
    }
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    selectedOption: {
      type: Array,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
  async created() {
    this.selected = this.$props.selectedOption
  },
  mounted() {
    this.onSearchOption('', () => {})
  },
  methods: {
    handleChangeStatuses(options: Option[]) {
      if (options) {
        this.selected = [options].flat()
      } else {
        this.selected = []
      }
    },
    onSearchOption(search: string, loading: (arg: boolean) => void) {
      loading(true)
      const filterOptions = this.$props.options.filter((option: Option) =>
        option.name.toLowerCase().includes(search.toLowerCase())
      )
      this.filterOptions = filterOptions
      loading(false)
    },
  },
})
