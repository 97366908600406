import { Controller } from 'stimulus'
import AlertController from '~/controllers/alert_controller'
import { findController } from '~/lib/stimulus'

export default class FormAlertController extends Controller {
  static targets = ['button']

  buttonTarget!: HTMLElement

  initialize(): void {
    this.hookOnclick()
  }

  private hookOnclick(): void {
    this.buttonTarget.onclick = (): false => {
      this.showAlert()

      return false
    }
  }

  private showAlert(): void {
    const alert = findController<AlertController>('alert')
    if (!alert) {
      throw new Error('alertが見つかりません')
    }

    alert.show({
      title: this.data.get('title') || '',
      description: this.data.get('description') || '',
      negativeBtnLabel: this.data.get('negative-btn-label') || '',
      positiveBtnLabel: this.data.get('positive-btn-label') || '',
      onClickPositive: () => this.submit(),
    })
  }

  private submit(): void {
    const { element } = this.data
    if (!(element instanceof HTMLFormElement)) {
      throw new TypeError()
    }

    element.submit()
  }
}
