import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'
import paramsSerializer from '~/api/paramsSerializer'

export const getKuronekoShippingPrices = (
  from: string,
  to: string,
  invoice_kb: string
): Promise<AxiosResponse<Api.KuronekoShippingPrices.Response>> => {
  return axios.get(`/api/kuroneko_shipping_prices`, {
    params: {
      from,
      to,
      invoice_kb,
    },
    paramsSerializer,
  })
}
