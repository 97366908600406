var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ScrollArea" },
    [
      _c("transition", { attrs: { name: _vm.animationType } }, [
        _vm.currentSheet
          ? _c(
              "div",
              {
                key: _vm.currentSheet.title + _vm.currentIndex,
                staticClass: "Sheet",
              },
              [
                _c("header", { staticClass: "Header" }, [
                  _c("div", { staticClass: "HeaderIcon" }, [
                    _vm.isBackable
                      ? _c("span", {
                          staticClass: "jirafficon jirafficon-arrow-left",
                          on: { click: _vm.back },
                        })
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("h2", { staticClass: "HeaderText" }, [
                    _vm._v(_vm._s(_vm.currentSheet.title)),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "HeaderIcon" }),
                ]),
                _vm._v(" "),
                _c(
                  "ul",
                  { staticClass: "List" },
                  _vm._l(_vm.currentSheet.list, function (cell, i) {
                    return _c(
                      "li",
                      { key: cell.label + i, staticClass: "Cell" },
                      [
                        cell.heading
                          ? _c(
                              "h3",
                              { staticClass: "PathCell PathCell-Heading" },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(cell.label) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _c(
                              "a",
                              {
                                staticClass: "PathCell",
                                on: {
                                  click: (e) => {
                                    _vm.selected(cell, e)
                                  },
                                },
                              },
                              [
                                _c("label", [_vm._v(_vm._s(cell.label))]),
                                _vm._v(" "),
                                _c("span", {
                                  staticClass:
                                    "jirafficon jirafficon-arrow-right",
                                }),
                              ]
                            ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }