import { Controller } from 'stimulus'
import Vue from 'vue'
import { on as noScrollOn, off as noScrollOff } from 'no-scroll'

export default class extends Controller {
  static targets = ['element', 'contents', 'closeBtn']

  variantSetup = false

  closeTriggerName = ''

  elementTarget?: HTMLElement

  contentsTarget?: HTMLElement

  closeBtnTarget?: HTMLElement

  initialize(): void {
    if (!this.elementTarget) return
    this.elementTarget.classList.add('modal--hidden')
    this.elementTarget.addEventListener('animationend', () => {
      this.onAnimationEnd()
    })
  }

  setVariant(variant: Modal.Variants = 'fixed-size'): void {
    if (!this.contentsTarget || !this.closeBtnTarget || !this.elementTarget)
      return

    this.contentsTarget.classList.add(`modal__contents--${variant}`)
    this.closeBtnTarget.classList.add(`modal__close-btn--${variant}`)
    this.elementTarget.classList.add(`modal__element--${variant}`)
    this.variantSetup = true
  }

  setCloseTriggerName(closeTriggerName: string): void {
    if (!closeTriggerName) return

    this.closeTriggerName = closeTriggerName
  }

  show(element: Element): void {
    if (!this.elementTarget || !this.contentsTarget) return
    // default setup to fixed-size modal
    if (!this.variantSetup) this.setVariant()

    this.elementTarget.classList.remove('modal--hidden')
    this.elementTarget.classList.add('modal--shown')
    this.clearModal()
    noScrollOn()
    if (element) {
      this.contentsTarget.appendChild(element)
    }
  }

  showWithVue(
    ComponentClass: typeof Vue,
    options: Vue.ComponentOptions<Vue> = {}
  ): Vue | undefined {
    if (!this.contentsTarget) return undefined
    // default setup to fixed-size modal
    if (!this.variantSetup) this.setVariant()

    const component = new ComponentClass(options).$mount()
    this.contentsTarget.appendChild(component.$el)
    this.show(component.$el)
    return component
  }

  close(): void {
    if (!this.elementTarget) return
    this.elementTarget.classList.remove('modal--shown')
    this.elementTarget.classList.add('modal--removing')
    noScrollOff()

    if (this.closeTriggerName)
      document.dispatchEvent(new CustomEvent(this.closeTriggerName))
  }

  clearModal(): void {
    if (!this.contentsTarget) return
    this.contentsTarget.innerHTML = ''
  }

  onAnimationEnd(): void {
    if (!this.elementTarget) return
    if (this.elementTarget.classList.contains('modal--removing')) {
      this.elementTarget.classList.add('modal--hidden')
      this.elementTarget.classList.remove('modal--removing')
    }
  }
}
