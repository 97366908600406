var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "label",
    { staticClass: "form__radiobox-label" },
    [
      _c("input", {
        staticClass: "form__radio",
        attrs: { name: _vm.name, type: "radio", disabled: _vm.disabled },
        domProps: { value: _vm.value, checked: _vm.checked },
        on: { change: _vm.updateValue },
      }),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }