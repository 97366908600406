import { Controller } from 'stimulus'
import AutocompletableSelectBox from '~/components/molecules/form/AutocompletableSelectBox.vue'

export default class VueSelectController extends Controller {
  initialize(): void {
    const props = JSON.parse(this.data.get('props') || '{}')

    const component = new AutocompletableSelectBox({
      propsData: props,
    }).$mount()
    this.element.appendChild(component.$el)
  }
}
