import { findController } from '~/lib/stimulus'
import ModalController from '~/controllers/modal_controller'

export default function (
  el: HTMLElement,
  variant: Modal.Variants = 'fixed-size',
  closeTriggerName = ''
): void {
  const modal = findController<ModalController>('modal')
  if (!modal) return

  modal.setVariant(variant)
  modal.setCloseTriggerName(closeTriggerName)
  modal.show(el)
}
