
import Vue from 'vue'

type Variant = 'default' | 'success' | 'warning' | 'notice'

export interface SnackAttributes {
  icon?: string
  title: string
  variant?: Variant
}

interface Snack extends SnackAttributes {
  id: number
}

interface Data {
  snacks: Snack[]
  nextSnackId: number
}

interface SnackMethods {
  addSnack(snack: SnackAttributes, activeTime?: number): void
  removeSnack(id: number): void
  snackClass(snack: Snack): string
}

interface Computed {
  displayedSnacks: Snack[]
}
interface Props {}

const MAX_SNACK_ID = 100000
const SNACK_ACTIVE_TIME = 5000

export default Vue.extend<Data, SnackMethods, Computed, Props>({
  data() {
    return {
      snacks: [],
      nextSnackId: 1,
    }
  },
  computed: {
    displayedSnacks(): Snack[] {
      return this.snacks.filter<Snack>(
        (_snack, index): _snack is Snack => index === this.snacks.length - 1
      )
    },
  },
  methods: {
    snackClass(snack): string {
      return `snack snack--${snack.variant || 'default'}`
    },
    addSnack(snack: SnackAttributes, activeTime?: number): number {
      const id = this.nextSnackId
      this.snacks.push({
        variant: 'default',
        id,
        ...snack,
      })

      this.nextSnackId += 1
      if (this.nextSnackId > MAX_SNACK_ID) {
        this.nextSnackId = 1
      }

      setTimeout(() => {
        this.removeSnack(id)
      }, activeTime || SNACK_ACTIVE_TIME)

      return id
    },
    removeSnack(): void {
      this.snacks = new Array<Snack>()
    },
  },
})
