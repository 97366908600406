import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'

export const getItemEstimates = (
  price: number,
  charge_rate: number,
  secure: boolean,
  isPrivate: boolean,
  goodsId: number | undefined,
  time: string
): Promise<AxiosResponse<Api.Items.Estimates.Response>> => {
  return axios.get('/mypage/items/estimates', {
    params: {
      'item[price]': price,
      'item[secure]': secure,
      'item[charge_rate]': charge_rate,
      'item[private]': isPrivate,
      'item[goods_id]': goodsId,
      request_time: time,
    },
  })
}
