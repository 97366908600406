import { AxiosResponse } from 'axios'
import { Controller } from 'stimulus'
import { getCityInfoByPostCode } from '~/api/mypage/postcode'
import { findController } from '~/lib/stimulus'
import ModalController from '~/controllers/modal_controller'

const NUMBER_REGEX = /[0-9０-９]/

export default class MypageAddressController extends Controller {
  static targets: string[] = [
    'addressForm',
    'cityCodeSl',
    'cityTxt',
    'addressTxt',
    'postCodeTxt',
    'submitAddressForm',
    'confirmModal',
  ]

  addressFormTarget?: HTMLFormElement

  cityCodeSlTarget?: HTMLSelectElement

  cityTxtTarget?: HTMLInputElement

  addressTxtTarget?: HTMLInputElement

  postCodeTxtTarget?: HTMLInputElement

  confirmModalTarget?: HTMLElement

  loadCityInfo(event: Event): void {
    event.preventDefault()
    const postCodeVal = this.postCodeTxtTarget?.value
    const postCodeBtn = event.target
    if (!postCodeVal || !(postCodeBtn instanceof HTMLButtonElement)) return

    postCodeBtn.disabled = true
    getCityInfoByPostCode(postCodeVal)
      .then((response: AxiosResponse<Api.Mypage.Postcode.Response>) => {
        const { data } = response

        if (
          !data.postcode ||
          !(this.cityCodeSlTarget instanceof HTMLSelectElement) ||
          !(this.cityTxtTarget instanceof HTMLInputElement)
        )
          return

        const { postcode } = data
        this.cityCodeSlTarget.value = postcode.city_code.toString()
        this.cityTxtTarget.value = postcode.address
      })
      .finally(() => {
        postCodeBtn.disabled = false
      })
  }

  submitAddressForm(event: Event): void {
    event.preventDefault()
    const address = this.addressTxtTarget?.value || ''

    if (address.match(NUMBER_REGEX)) {
      this.addressFormTarget?.submit()
    } else {
      const modal = findController<ModalController>('modal')
      if (!modal) return

      const modalContent = document.createElement('div')
      modalContent.innerHTML = this.confirmModalTarget?.innerHTML || ''
      modal.show(modalContent.children[0])
    }
  }
}
