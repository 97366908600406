
import Vue from 'vue'
import firstGradeImage from 'images/oripa/icon_first_grade.png'
import secondGradeImage from 'images/oripa/icon_second_grade.png'
import thirdGradeImage from 'images/oripa/icon_third_grade.png'
import fourthGradeImage from 'images/oripa/icon_fourth_grade.png'
import fifthGradeImage from 'images/oripa/icon_fifth_grade.png'
import couponImage from 'images/oripa/thumbnail_coupon.jpg'
import numberFormattable from '~/components/mixins/numberFormattable'

interface Data {}

interface Methods {
  onOpenConvertCouponModal(): void
  onTransitRequestShippingPage(): void
}

interface Computed {
  isOripaCard: boolean
  isStored: boolean
  isShippingRequested: boolean
  isShipped: boolean
  isConverted: boolean
  isConvertedToPoints: boolean
  prizeCardInnerClass: { [key: string]: boolean }
  openConvertCouponBtnClass: { [key: string]: boolean }
  transitRequestShippingBtnClass: { [key: string]: boolean }
  statusText: string
  gradeImage: string
  couponImage: string
  targetCategory: string
  couponName: string
  shippingRequestDueDateInWords: string
  couponExpiredAt: string
  convertPayoutCoupon: string
  existsConvertablePoints: boolean
}

interface Props {
  lotteryResultPrize: Model.LotteryResultPrize
  isMypage: boolean
}

type PrizeCardInnerClasses =
  | 'lottery-result-prize-card__oripa-card--around-border'
  | 'lottery-result-prize-card__oripa-card--disabled'
type openConvertCouponBtnClasses = 'btn--conversion' | 'btn--disabled'
type transitRequestShippingBtnClasses = 'btn--ghost-primary' | 'btn--disabled'

export default Vue.extend<Data, Methods, Computed, Props>({
  mixins: [numberFormattable],
  data() {
    return {}
  },
  props: {
    lotteryResultPrize: {
      type: Object,
      required: true,
    },
    isMypage: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    onOpenConvertCouponModal(): void {
      this.$emit('onOpenConvertCouponModal', this.lotteryResultPrize)
    },
    onTransitRequestShippingPage(): void {
      this.$emit('onTransitRequestShippingPage', this.lotteryResultPrize.id)
    },
  },
  computed: {
    isOripaCard(): boolean {
      return this.lotteryResultPrize.prizable_type === 'Oripa::Card'
    },
    isStored(): boolean {
      return this.lotteryResultPrize.status === 'stored'
    },
    isShippingRequested(): boolean {
      return this.lotteryResultPrize.status === 'shipping_requested'
    },
    isShipped(): boolean {
      return this.lotteryResultPrize.status === 'shipped'
    },
    isConverted(): boolean {
      return this.lotteryResultPrize.status === 'converted'
    },
    isConvertedToPoints(): boolean {
      return this.lotteryResultPrize.status === 'converted_to_points'
    },
    prizeCardInnerClass(): { [key in PrizeCardInnerClasses]: boolean } {
      return {
        'lottery-result-prize-card__oripa-card--around-border': this.isStored,
        'lottery-result-prize-card__oripa-card--disabled':
          this.isShipped || this.isConverted || this.isConvertedToPoints,
      }
    },
    openConvertCouponBtnClass(): {
      [key in openConvertCouponBtnClasses]: boolean
    } {
      return {
        'btn--conversion': this.isStored,
        'btn--disabled':
          this.isShipped ||
          this.isShippingRequested ||
          this.isConverted ||
          this.isConvertedToPoints,
      }
    },
    transitRequestShippingBtnClass(): {
      [key in transitRequestShippingBtnClasses]: boolean
    } {
      return {
        'btn--ghost-primary': this.isStored,
        'btn--disabled':
          this.isShipped ||
          this.isShippingRequested ||
          this.isConverted ||
          this.isConvertedToPoints,
      }
    },
    statusText(): string {
      let manufacturedText: string

      switch (this.lotteryResultPrize.status) {
        case 'stored':
          if (!this.lotteryResultPrize.shipping_request_due_date) {
            manufacturedText = `発送期限: なし`
          } else {
            manufacturedText = `発送期限: ${this.lotteryResultPrize.shipping_request_due_date} 23:59`
          }
          break
        case 'shipping_requested':
          manufacturedText = `発送申請済み（${this.lotteryResultPrize.shipping_requested_at}）`
          break
        case 'shipped':
          manufacturedText = `発送完了（${this.lotteryResultPrize.shipped_at}）`
          break
        case 'converted':
          manufacturedText = !this.lotteryResultPrize.convertable_points
            ? 'クーポン変換済み'
            : 'クーポン + ポイント変換済み'
          break
        case 'converted_to_points':
          manufacturedText = 'ポイント変換済み'
          break
        default:
          manufacturedText = ''
      }

      return manufacturedText
    },
    gradeImage(): string {
      switch (this.lotteryResultPrize.grade) {
        case 'first_winning':
          return firstGradeImage
        case 'second_winning':
          return secondGradeImage
        case 'third_winning':
          return thirdGradeImage
        case 'fourth_winning':
          return fourthGradeImage
        case 'fifth_winning':
          return fifthGradeImage
        default:
          return ''
      }
    },
    couponImage(): string {
      return couponImage
    },
    targetCategory(): string {
      if (
        !this.lotteryResultPrize.coupon_condition_goods_name &&
        !this.lotteryResultPrize.coupon_condition_brand_name &&
        !this.lotteryResultPrize.coupon_condition_series_name
      ) {
        return '対象カテゴリ: 全て'
      }

      const goods = this.lotteryResultPrize.coupon_condition_goods_name
        ? `${this.lotteryResultPrize.coupon_condition_goods_name} `
        : ''
      const brand = this.lotteryResultPrize.coupon_condition_brand_name
        ? `${this.lotteryResultPrize.coupon_condition_brand_name} `
        : ''
      const series = this.lotteryResultPrize.coupon_condition_series_name
        ? `${this.lotteryResultPrize.coupon_condition_series_name}`
        : ''

      return `対象カテゴリ: ${goods}${brand}${series}`.trimEnd()
    },
    couponName(): string {
      return `${this.lotteryResultPrize.prize_title}offクーポン`
    },
    shippingRequestDueDateInWords(): string {
      return `${this.lotteryResultPrize.shipping_request_due_date_in_words}`
    },
    couponExpiredAt(): string {
      if (this.lotteryResultPrize.coupon_expired_at) {
        return `有効期限: ${this.lotteryResultPrize.coupon_expired_at} 23:59`
      }

      return 'なし'
    },
    convertPayoutCoupon(): string {
      return `${this.lotteryResultPrize.convertable_coupon_discount_name}offクーポン`
    },
    existsConvertablePoints(): boolean {
      return !!this.lotteryResultPrize.convertable_points
    },
  },
})
