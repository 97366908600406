var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    class: "form__input " + _vm.additionalClassName,
    attrs: { placeholder: _vm.placeholder, type: _vm.type },
    domProps: { value: _vm.value },
    on: {
      input: _vm.updateValue,
      focus: function ($event) {
        return _vm.$emit("focus", $event)
      },
      blur: function ($event) {
        return _vm.$emit("blur", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }