import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['gauge', 'amount', 'remaining']

  gaugeTarget?: HTMLElement

  amountTarget?: HTMLElement

  remainingTarget?: HTMLElement

  initialize(): void {
    this.adjustGauge()
    this.changeColorGauge()
  }

  private adjustGauge(): void {
    if (!this.gaugeTarget || !this.amountTarget || !this.remainingTarget) return

    const remainingTextContent = this.remainingTarget.textContent
    const amountTextContent = this.amountTarget.textContent
    if (remainingTextContent === null || amountTextContent === null) return

    const remaining: number = parseInt(
      remainingTextContent.replace(/,/g, ''),
      10
    )
    const amount: number = parseInt(amountTextContent.replace(/,/g, ''), 10)
    const remainingRate: number = remaining / amount
    const somewhatLessBreakPoint = 0.3
    const lessBreakPoint = 0.1

    if (remainingRate >= somewhatLessBreakPoint) {
      this.gaugeTarget.classList.add('remaining-amount-meter__gauge--many')
    } else if (
      remainingRate < somewhatLessBreakPoint &&
      remainingRate >= lessBreakPoint
    ) {
      this.gaugeTarget.classList.add(
        'remaining-amount-meter__gauge--somewhat-less'
      )
    } else {
      this.gaugeTarget.classList.add('remaining-amount-meter__gauge--less')
    }
  }

  private changeColorGauge(): void {
    if (!this.gaugeTarget || !this.amountTarget || !this.remainingTarget) return

    const gaugeWidth: number = this.gaugeTarget.clientWidth
    const remainingTextContent = this.remainingTarget.textContent
    const amountTextContent = this.amountTarget.textContent
    if (remainingTextContent === null || amountTextContent === null) return

    const remaining: number = parseInt(
      remainingTextContent.replace(/,/g, ''),
      10
    )
    const amount: number = parseInt(amountTextContent.replace(/,/g, ''), 10)
    const minWidth = '24px'

    if (remaining === 0) {
      this.gaugeTarget.style.width = '0'
    } else if (remaining !== 0 && gaugeWidth * (remaining / amount) < 24) {
      this.gaugeTarget.style.width = minWidth
    } else {
      this.gaugeTarget.style.width = `${gaugeWidth * (remaining / amount)}px`
    }
  }
}
