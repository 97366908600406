import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['confirmModalPositiveButton', 'confirmInput']

  confirmModalPositiveButtonTarget?: HTMLElement

  inputChangeAction(e: Event): void {
    if (!e.target) return

    if (
      !(this.confirmModalPositiveButtonTarget instanceof HTMLElement) ||
      !(e.target instanceof HTMLInputElement)
    ) {
      throw new TypeError()
    }

    const { confirmText } = e.target.dataset

    if (e.target.value === confirmText) {
      this.confirmModalPositiveButtonTarget.removeAttribute('disabled')
      this.confirmModalPositiveButtonTarget.classList.remove('disabled')
    } else {
      this.confirmModalPositiveButtonTarget.setAttribute('disabled', 'disabled')
      this.confirmModalPositiveButtonTarget.classList.add('disabled')
    }
  }
}
