var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("textarea", {
    staticClass: "form__input form__input--textarea",
    domProps: { value: _vm.value },
    on: { input: _vm.updateValue, change: _vm.updateValue },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }