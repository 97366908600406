
import { Component, Vue } from 'vue-property-decorator'
import { Editor, EditorContent } from 'tiptap'

@Component({
  components: {
    EditorContent,
  },
  props: {
    editor: Editor,
  },
})
export default class extends Vue {
  // FIXME
  /* eslint-disable */
  // ts-ignore
  private editor: any
  /* eslint-enable */
}
