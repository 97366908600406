import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['importCsvInput', 'fileNameHolder']

  importCsvInputTarget?: HTMLInputElement

  fileNameHolderTarget?: HTMLElement

  onChange(): void {
    if (!(this.importCsvInputTarget instanceof HTMLInputElement)) {
      throw new TypeError()
    }

    if (!(this.fileNameHolderTarget instanceof HTMLElement)) {
      throw new TypeError()
    }

    const [fileName] = this.importCsvInputTarget.value.split('\\').reverse()
    this.fileNameHolderTarget.textContent = fileName
  }
}
