import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'

export const getSeries = (
  goodsId: number,
  brandId: number
): Promise<AxiosResponse<Api.Series.Index.Response>> => {
  return axios.get(`/api/brands/${brandId}/series`, {
    params: {
      goods_id: goodsId,
    },
  })
}
