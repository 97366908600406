var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "lottery-result-prize-cards" },
    _vm._l(_vm.lotteryResultPrizes, function (lotteryResultPrize) {
      return _c("lottery-result-prize-card", {
        key: lotteryResultPrize.id,
        attrs: {
          lotteryResultPrize: lotteryResultPrize,
          isMypage: _vm.is_mypage,
        },
        on: {
          onOpenConvertCouponModal: _vm.onOpenConvertCouponModal,
          onTransitRequestShippingPage: _vm.onTransitRequestShippingPage,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }