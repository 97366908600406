import '~/core/application'
import { Controller } from 'stimulus'
import Vue from 'vue'
import SearchItems from '~/components/organisms/shortcodes/blogs/SearchItems.vue'

interface SearchItemsProps {
  [key: string]: unknown
}

interface Data {
  propsData?: SearchItemsProps
}

export default class SearchItemsController extends Controller {
  initialize(): void {
    const queryJson = this.data.get('query') || '{}'
    const title = this.data.get('title') || ''
    const moreLabel = this.data.get('more_label') || ''
    const noResultsLabel = this.data.get('no_results_label') || ''
    const otherKeywordLabel = this.data.get('other_keyword_label') || ''
    const searchParameters = JSON.parse(queryJson)

    new Vue({
      el: this.element,
      components: {
        SearchItems,
      },
      template: '<search-items v-bind="propsData"/>',
      data(): Data {
        return {
          propsData: {
            searchParameters,
            title,
            moreLabel,
            noResultsLabel,
            otherKeywordLabel,
          },
        }
      },
    })
  }
}
