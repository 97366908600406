import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'
import paramsSerializer from '../paramsSerializer'

export const searchItems = (
  parameters: Object
): Promise<AxiosResponse<Api.Items.Search.Response>> => {
  // NOTE: api用とは公開可能な series が異なるため、api/items/search を使用しない
  return axios.get(`/items/search.json?`, {
    params: parameters,
    paramsSerializer,
  })
}
