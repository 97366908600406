var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "lottery-result-prize-card" }, [
    !_vm.isMypage && !_vm.isOripaCard
      ? _c("div", { staticClass: "lottery-result-prize-card__coupon" }, [
          _c(
            "div",
            { staticClass: "lottery-result-prize-card__thumbnail-wrapper" },
            [
              _c("img", {
                staticClass: "lottery-result-prize-card__thumbnail",
                attrs: { src: _vm.couponImage },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "lottery-result-prize-card__info" }, [
            _c(
              "div",
              { staticClass: "lottery-result-prize-card__info-text-area" },
              [
                _c(
                  "p",
                  { staticClass: "lottery-result-prize-card__prize-title" },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.couponName) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "lottery-result-prize-card__target-category" },
                  [
                    _vm._v(
                      "\n          " + _vm._s(_vm.targetCategory) + "\n        "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "p",
                  { staticClass: "lottery-result-prize-card__expired-at" },
                  [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.couponExpiredAt) +
                        "\n        "
                    ),
                  ]
                ),
              ]
            ),
          ]),
        ])
      : _c(
          "div",
          {
            class: [
              "lottery-result-prize-card__oripa-card",
              _vm.prizeCardInnerClass,
            ],
          },
          [
            _c(
              "div",
              { staticClass: "lottery-result-prize-card__info-wrapper" },
              [
                _c(
                  "div",
                  { staticClass: "lottery-result-prize-card__grade-wrapper" },
                  [
                    _c("img", {
                      staticClass: "lottery-result-prize-card__grade",
                      attrs: { src: _vm.gradeImage },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "lottery-result-prize-card__thumbnail-wrapper",
                  },
                  [
                    _c("img", {
                      staticClass: "lottery-result-prize-card__thumbnail",
                      attrs: { src: _vm.lotteryResultPrize.thumbnail_url },
                    }),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "lottery-result-prize-card__info" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "lottery-result-prize-card__info-text-area lottery-result-prize-card__info-text-area--card",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass:
                            "lottery-result-prize-card__lottery-title",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.lotteryResultPrize.lottery_title) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "p",
                        {
                          staticClass: "lottery-result-prize-card__prize-title",
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.lotteryResultPrize.prize_title) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "lottery-result-prize-card__date-area" },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "lottery-result-prize-card__expired-at",
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.statusText) +
                                  "\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isStored
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "lottery-result-prize-card__date-in-words",
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.shippingRequestDueDateInWords
                                      ) +
                                      "\n            "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.isStored
              ? _c(
                  "div",
                  { staticClass: "lottery-result-prize-card__button-area" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "lottery-result-prize-card__transit-request-shipping-btn-wrapper",
                      },
                      [
                        _c(
                          "button",
                          {
                            class: [
                              "lottery-result-prize-card__transit-request-shipping-btn btn btn--small btn--full",
                              _vm.transitRequestShippingBtnClass,
                            ],
                            attrs: { disabled: !_vm.isStored },
                            on: { click: _vm.onTransitRequestShippingPage },
                          },
                          [
                            _c("i", {
                              staticClass:
                                "fs-m mr-xs jirafficon jirafficon-track",
                            }),
                            _vm._v("\n          発送申請\n        "),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "lottery-result-prize-card__open-convert-coupon-btn-wrapper",
                      },
                      [
                        _c(
                          "button",
                          {
                            class: [
                              "lottery-result-prize-card__open-convert-coupon-btn btn btn--small btn--full",
                              _vm.openConvertCouponBtnClass,
                            ],
                            attrs: { disabled: !_vm.isStored },
                            on: { click: _vm.onOpenConvertCouponModal },
                          },
                          [
                            _vm._v("\n          クーポン\n          "),
                            _vm.existsConvertablePoints
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "lottery-result-prize-card__open-convert-points-label",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fs-s mr-xs ml-xs jirafficon jirafficon-plus",
                                    }),
                                    _vm._v(
                                      "\n            ポイント\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v("\n          に変換\n        "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "lottery-result-prize-card__open-convert-payouts",
                          },
                          [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "lottery-result-prize-card__open-convert-payout-coupon",
                              },
                              [
                                _c("i", {
                                  staticClass:
                                    "fs-l mr-xs jirafficon jirafficon-coupon",
                                }),
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm.convertPayoutCoupon) +
                                    "\n          "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.existsConvertablePoints
                              ? _c(
                                  "span",
                                  {
                                    staticClass:
                                      "lottery-result-prize-card__open-convert-payout-points",
                                  },
                                  [
                                    _c("i", {
                                      staticClass:
                                        "fs-m mr-xs ml-xs text-gray jirafficon jirafficon-plus",
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass:
                                        "fs-m mr-xs jirafficon jirafficon-point",
                                    }),
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.format(
                                            this.lotteryResultPrize
                                              .convertable_points
                                          )
                                        ) +
                                        "\n            ポイント\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }