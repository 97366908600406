import { Controller } from 'stimulus'

export default class FormImagesController extends Controller {
  onClickImage(event: Event & { target: HTMLElement }): void {
    const inputImage: HTMLElement | null = document.getElementById(
      `form-images-input-${event.target.dataset.value}`
    )
    if (!inputImage) {
      return
    }

    inputImage.click()
  }

  onChange(e: Event): void {
    const { target } = e
    if (!(target instanceof HTMLInputElement)) throw new TypeError()

    if (!target.files) return

    const reader = new FileReader()
    reader.readAsDataURL(target.files[target.files.length - 1])
    reader.onload = (): void => {
      if (typeof reader.result === 'string') {
        const imageTarget = document.getElementById(
          `form-images-${target.dataset.value}`
        )
        if (!(imageTarget instanceof HTMLImageElement)) throw new TypeError()

        const imageBoxTarget: HTMLElement | null = document.getElementById(
          `form-images-box-${target.dataset.value}`
        )

        if (!imageTarget || !imageBoxTarget) {
          return
        }

        imageTarget.src = reader.result
        imageTarget.setAttribute('style', 'display: block')
      }
    }
  }
}
