import { Controller } from 'stimulus'

export default class extends Controller {
  connect(): void {
    const alertElements: NodeListOf<HTMLElement> =
      document.querySelectorAll('.alert')
    alertElements.forEach((alertElement: HTMLElement) => {
      alertElement.classList.add('show')
    })
  }
}
