var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "form-group",
    [
      _c("form-label", [_vm._v(_vm._s(_vm.$props.label))]),
      _vm._v(" "),
      _c(
        "form-control",
        [
          _c("searchable-select-box", {
            attrs: {
              name: "searchable-select-box",
              selected: _vm.selected,
              options: _vm.filterOptions,
              multiple: _vm.$props.multiple,
            },
            on: { input: _vm.handleChangeStatuses, search: _vm.onSearchOption },
          }),
          _vm._v(" "),
          _vm._l(_vm.selected, function (selected) {
            return _c("form-input", {
              key: selected.id,
              attrs: {
                type: "hidden",
                value: selected.id,
                id: "select-box-input-hidden",
                name: _vm.$props.name,
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }