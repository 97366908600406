export const execCopy = (value: string): boolean => {
  const tmp = document.createElement('div')
  const pre = document.createElement('pre')

  pre.style.webkitUserSelect = 'auto'
  pre.style.userSelect = 'auto'
  pre.style.opacity = '0'

  tmp.style.position = 'fixed'
  tmp.style.top = '-2000%'

  tmp.appendChild(pre).textContent = value

  document.body.appendChild(tmp)
  const selection = document.getSelection()
  if (!selection) return false
  selection.selectAllChildren(tmp)

  const result = document.execCommand('copy')

  document.body.removeChild(tmp)

  return result
}
