import { Controller } from 'stimulus'

export default class extends Controller {
  showPreviewImage(e: Event): void {
    if (!(e.currentTarget instanceof HTMLInputElement)) return

    const { currentTarget } = e
    const targetId = currentTarget.id

    if (!currentTarget.files) return

    const imageFile = currentTarget.files[0]

    if (imageFile.type.indexOf('image') < 0) return
    const previewAreaId = `${targetId}-preview`
    const reader = new FileReader()

    reader.onload = () => {
      const previewImageArea = document.getElementById(previewAreaId)
      if (
        !(previewImageArea instanceof HTMLElement) ||
        typeof reader.result !== 'string'
      )
        return

      previewImageArea.innerHTML = ''
      const image = document.createElement('img')
      image.src = reader.result
      image.title = imageFile.name
      previewImageArea.appendChild(image)
    }

    reader.readAsDataURL(imageFile)
  }
}
