import { Controller } from 'stimulus'
import { showLoading, hideLoading } from '~/core/loadingModal'
import { postComment } from '~/api/comments/index'

export default class extends Controller {
  submitForm(event: Event) {
    event.preventDefault()
    const form = event.currentTarget
    if (!(form instanceof HTMLFormElement)) return
    const commentsWrapper = form.closest('#comments_wrapper')
    if (!commentsWrapper) return

    showLoading()
    postComment(form.action, new FormData(form))
      .then((response) => {
        commentsWrapper.innerHTML = response.data.html
      })
      .finally(() => {
        hideLoading()
      })
  }
}
