import '~/lib/sentry'
import 'ts-polyfill'
import 'promise-polyfill/src/polyfill'
import '~/lib/stimulus'
import lozad from 'lozad'
import Vue from 'vue'
import initGA from '~/lib/ga'
import onEnter from '~/core/onEnter'
import { initializeSnackBar } from '~/core/snackBar'
import SearchForm from '~/components/organisms/SearchForm.vue'

interface SearchFormProps {
  [key: string]: unknown
}

interface Data {
  propsData: SearchFormProps
}

const renderSearchForm = () => {
  const els = document.querySelectorAll('.js-search-form')

  els.forEach((el) => {
    if (!el || !(el instanceof HTMLElement)) return

    const propsStr = el.dataset.props || ''
    const propsData = JSON.parse(propsStr)

    new Vue({
      el,
      components: {
        SearchForm,
      },
      template: '<search-form v-bind="propsData" />',
      data(): Data {
        return {
          propsData,
        }
      },
    })
  })
}

onEnter(() => {
  initGA()
  const observer = lozad()
  observer.observe()
  const body = document.querySelector('body')
  if (body) {
    body.classList.add('loaded')
  }
  initializeSnackBar()
  renderSearchForm()
})
