import { Controller } from 'stimulus'

export default class ShowMoreController extends Controller {
  static targets = ['content', 'label']

  contentTarget!: HTMLElement

  labelTarget!: HTMLElement

  private isShown = false

  private static readonly DEFAULT_CONTENT_HEIGHT: number = 220

  initialize(): void {
    if (!this.contentTarget || !this.contentTarget.firstElementChild) {
      return
    }

    if (
      this.contentTarget.firstElementChild.clientHeight <
      ShowMoreController.DEFAULT_CONTENT_HEIGHT
    ) {
      this.contentTarget.classList.add('show-more__no-display')
      this.labelTarget.classList.add('show-more__no-display')
    }
  }

  toggle(): void {
    if (this.isShown) {
      this.contentTarget.style.height = `${ShowMoreController.DEFAULT_CONTENT_HEIGHT}px`
      this.updateShowStatus(false)
    } else {
      this.contentTarget.style.height = `${this.contentHeight}px`
      this.updateShowStatus(true)
    }
  }

  updateShowStatus(flag: boolean): void {
    this.isShown = flag
  }

  private get contentHeight(): number {
    if (!this.contentTarget || !this.contentTarget.firstElementChild) {
      return ShowMoreController.DEFAULT_CONTENT_HEIGHT
    }

    return this.contentTarget.firstElementChild.clientHeight
  }
}
