import { Controller } from 'stimulus'
import { findController } from '~/lib/stimulus'
import ModalController from '~/controllers/modal_controller'

export default class VaultModalController extends Controller {
  static targets: string[] = ['confirmModal']

  confirmModalTarget?: HTMLElement

  show(event: Event): void {
    event.preventDefault()
    const modalContent = document.createElement('div')
    modalContent.innerHTML = this.confirmModalTarget?.innerHTML || ''
    const modal = findController<ModalController>('modal')
    if (!modal) return
    modal.show(modalContent.children[0])
  }
}
