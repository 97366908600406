import { Controller } from 'stimulus'
import { uploadImage } from '~/api/administrators/upload_image'

export default class extends Controller {
  processImage(e: Event): void {
    if (!(e.currentTarget instanceof HTMLInputElement)) return
    const { currentTarget } = e
    const targetDataId = currentTarget.dataset.targetId || ''
    const textAreaTarget = document.getElementById(targetDataId)

    if (
      !currentTarget.files ||
      !(textAreaTarget instanceof HTMLTextAreaElement)
    )
      return
    uploadImage(currentTarget.files[0])
      .then((response) => {
        const imageTag = `<img src="${response.data.image_url}">`
        const value = `${textAreaTarget.value}\n${imageTag}`
        textAreaTarget.value = value
      })
      .catch(() => {
        // eslint-disable-next-line no-alert
        alert('エラーが発生しました')
      })
  }
}
