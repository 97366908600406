
import Vue from 'vue'

export default Vue.extend({
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    value: {},
    inputType: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    selectorId: {
      type: String,
    },
    selectorClass: {
      type: String,
    },
    selectorWrapperClass: {
      type: String,
    },
  },
  methods: {
    updateValue(e: Event): void {
      const { target } = e
      if (!target || !(target instanceof HTMLSelectElement)) {
        throw new TypeError()
      }
      this.$emit('input', target.value)
      this.$emit('change', target.value)
    },
    additionalClassName(): string {
      return this.$props.error ? 'form__input--field-error' : ''
    },
  },
})
