import { Controller } from 'stimulus'
import AlertController from '~/controllers/alert_controller'
import { findController } from '~/lib/stimulus'

export default class BulkDiscountController extends Controller {
  static targets = [
    'previousDays',
    'count',
    'updateForm',
    'useRate',
    'useRateHidden',
    'usePrice',
    'rate',
    'price',
    'previousDaysHidden',
    'rateHidden',
    'priceHidden',
    'messages',
  ]

  previousDaysTarget?: HTMLSelectElement

  countTarget?: HTMLInputElement

  updateFormTarget?: HTMLFormElement

  useRateTarget?: HTMLInputElement

  useRateHiddenTarget?: HTMLInputElement

  usePriceTarget?: HTMLInputElement

  rateTarget?: HTMLInputElement

  priceTarget?: HTMLInputElement

  previousDaysHiddenTarget?: HTMLInputElement

  rateHiddenTarget?: HTMLInputElement

  priceHiddenTarget?: HTMLInputElement

  messagesTarget?: HTMLElement

  connect(): void {
    if (this.usePriceTarget && this.usePriceTarget.checked) {
      this.toggleFieldDisabled(false)
    } else if (this.useRateTarget && this.useRateTarget.checked) {
      this.toggleFieldDisabled(true)
    }
  }

  onChangePreviousDaysSelector(): void {
    if (this.previousDaysTarget && this.previousDaysHiddenTarget) {
      this.previousDaysHiddenTarget.value = this.previousDaysTarget.value
    }
  }

  onChangeDiscountRateTextField(): void {
    this.setValidationMessage('')
    if (this.rateHiddenTarget && this.rateTarget) {
      const adjustValue = Number(this.rateTarget.value).toFixed()
      this.rateTarget.value = adjustValue
      this.rateHiddenTarget.value = adjustValue
    }
  }

  onChangeDiscountPriceTextField(): void {
    this.setValidationMessage('')
    if (this.priceHiddenTarget && this.priceTarget) {
      const adjustValue = Number(this.priceTarget.value).toFixed()
      this.priceTarget.value = adjustValue
      this.priceHiddenTarget.value = adjustValue
    }
  }

  onChangeUseRateRedio(): void {
    const isPriceChecked = this.usePriceTarget && this.usePriceTarget.checked
    const isRateChecked = this.useRateTarget && this.useRateTarget.checked
    const useRateHiddenElement = this.useRateHiddenTarget
    if (!useRateHiddenElement) {
      throw new Error('useRateHiddenElementが見つかりません')
    }
    if (isPriceChecked) {
      useRateHiddenElement.value = 'false'
      this.toggleFieldDisabled(false)
    } else if (isRateChecked) {
      useRateHiddenElement.value = 'true'
      this.toggleFieldDisabled(true)
    }
    this.resetInputed()
    this.setValidationMessage('')
  }

  onSubmit(): void {
    const message: string = this.getValidationMessage()
    if (message.length > 0) {
      this.setValidationMessage(message)
    } else {
      this.showConfirm()
    }
  }

  private toggleFieldDisabled(isUseRate: boolean): void {
    if (this.rateTarget) {
      this.rateTarget.disabled = !isUseRate
    }
    if (this.priceTarget) {
      this.priceTarget.disabled = isUseRate
    }
  }

  private resetInputed(): void {
    const rate = this.rateTarget
    if (!rate) {
      throw new Error('rateが見つかりません')
    }
    const price = this.priceTarget
    if (!price) {
      throw new Error('priceが見つかりません')
    }
    const rateHidden = this.rateHiddenTarget
    if (!rateHidden) {
      throw new Error('rateHiddenが見つかりません')
    }
    const priceHidden = this.priceHiddenTarget
    if (!priceHidden) {
      throw new Error('priceHiddenが見つかりません')
    }
    rate.value = ''
    price.value = ''
    rateHidden.value = ''
    priceHidden.value = ''
  }

  private getValidationMessage(): string {
    let message = ''
    const countElement = this.countTarget
    if (!countElement) {
      throw new Error('countElementが見つかりません')
    }
    const isUseRate = this.useRateTarget && this.useRateTarget.checked

    const rateElement = document.getElementById('discounted_rate')
    if (!rateElement || !(rateElement instanceof HTMLInputElement)) {
      throw new TypeError()
    }

    const priceElement = document.getElementById('discounted_price')
    if (!priceElement || !(priceElement instanceof HTMLInputElement)) {
      throw new TypeError()
    }
    if (
      isUseRate &&
      (Number(rateElement.value) < 1 ||
        rateElement.value === '' ||
        rateElement.value === 'NaN')
    ) {
      message = '％値下げ は1より大きい整数値を指定してください。'
    } else if (
      !isUseRate &&
      (Number(priceElement.value) <= 0 ||
        priceElement.value === '' ||
        priceElement.value === 'NaN')
    ) {
      message = '円値下げ は0より大きい整数値を指定してください。'
    } else if (countElement.value === '0' || countElement.value === '') {
      message = '値下けできる商品がありません。'
    }
    return message
  }

  private setValidationMessage(message: string): void {
    if (this.messagesTarget) {
      this.messagesTarget.innerHTML = message
    }
  }

  private showConfirm(): void {
    let discountText = ''
    const alert = findController<AlertController>('alert')
    if (!alert) {
      throw new Error('alertが見つかりません')
    }
    const updateForm = this.updateFormTarget
    if (!updateForm) {
      throw new Error('updateFormが見つかりません')
    }
    const isUseRate = this.useRateTarget && this.useRateTarget.checked
    const countElement = this.countTarget
    if (!countElement) {
      throw new Error('countが見つかりません')
    }
    const discountRate = this.rateTarget
    if (!discountRate) {
      throw new Error('discountRateが見つかりません')
    }
    const discountPrice = this.priceTarget
    if (!discountPrice) {
      throw new Error('discountPriceが見つかりません')
    }

    const previousDaysSelectElement = this.previousDaysTarget
    if (!previousDaysSelectElement) {
      throw new Error('previousDaysSelectElementが見つかりません')
    }
    let previousDaysSelectIndex = previousDaysSelectElement.selectedIndex
    previousDaysSelectIndex = previousDaysSelectElement.selectedIndex
    if (!previousDaysSelectIndex) {
      previousDaysSelectIndex = 0
    }
    const previousDaysSelectText =
      previousDaysSelectElement.options[previousDaysSelectIndex].text
    if (isUseRate) {
      discountText = `${discountRate.value}%`
    } else {
      discountText = `${discountPrice.value}円`
    }

    const confirmForward = `一括値下げは取り消しができません。<br>対象商品や値下げ条件に間違いがないか再度ご確認ください。`
    const confirmBackward = `<br><br>最終更新日:${previousDaysSelectText} <br>対象商品:${countElement.value}点 <br>値下幅:${discountText}`

    alert.show({
      title: '本当に一括値下げを実行しますか？',
      description: confirmForward + confirmBackward,
      negativeBtnLabel: 'キャンセル',
      positiveBtnLabel: '実行',
      onClickPositive: () => {
        updateForm.submit()
        alert.close()
      },
    })
  }
}
