import Vue from 'vue'
import SnackBar, { SnackAttributes } from '~/components/atoms/SnackBar.vue'

let snackBar: Vue

export const initializeSnackBar = (): void => {
  snackBar = new Vue({
    el: '.js-snackbar',
    components: {
      SnackBar,
    },
    template: '<snack-bar ref="snackBar"/>',
  })
}

interface SnackBarComponent {
  addSnack(snack: SnackAttributes, activeTime?: number): void
}

const isSnackBar = (component: unknown): component is SnackBarComponent => {
  return !!component
}

export const showSnackBar = (
  snack: SnackAttributes,
  activeTime?: number
): void => {
  const component = snackBar.$refs.snackBar
  if (isSnackBar(component)) {
    component.addSnack(snack, activeTime)
  }
}
