var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "form__input py-m",
        attrs: { "area-label": _vm.name },
        on: { click: _vm.showSelectorModal },
      },
      [_vm._v("\n    " + _vm._s(_vm.selectedLabel) + "\n  ")]
    ),
    _vm._v(" "),
    _c("input", {
      attrs: { type: "hidden", name: _vm.name },
      domProps: { value: _vm.selectedOption ? _vm.selectedOption.value : null },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }