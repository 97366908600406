
import Vue from 'vue'

interface Props {
  hotTrendKeywords: string[]
  searchByHotTrendKeyword(keyword: string): void
}

export default Vue.extend<{}, {}, {}, Props>({
  props: {
    hotTrendKeywords: {
      type: Array,
      required: true,
    },
    searchByHotTrendKeyword: {
      type: Function,
      required: true,
    },
  },
})
