import { Controller } from 'stimulus'

export default class BlogsDisplayBannerController extends Controller {
  static targets = [
    'firstBanner',
    'secondBanner',
    'desktopContent',
    'desktopButtonClose',
  ]

  firstBannerTarget!: HTMLElement

  secondBannerTarget!: HTMLElement

  desktopContentTarget!: HTMLElement

  desktopButtonCloseTarget!: HTMLElement

  initialize(): void {
    this.attachFirstBanner()
    this.attachSecondBanner()
  }

  toggleContentDisplay(): void {
    if (!this.desktopContentTarget) throw new TypeError('no desktop content')

    if (!this.desktopButtonCloseTarget) {
      throw new TypeError('no desktop content')
    }

    if (this.desktopContentTarget.classList.contains('d-none')) {
      this.desktopButtonCloseTarget.classList.remove(
        'blogs-footer__close--minimized'
      )
      this.desktopContentTarget.classList.remove('d-none')
      this.desktopContentTarget.parentElement?.classList.remove(
        'blogs-footer__collapsible--minimized'
      )
    } else {
      this.desktopButtonCloseTarget.classList.add(
        'blogs-footer__close--minimized'
      )
      this.desktopContentTarget.classList.add('d-none')
      this.desktopContentTarget.parentElement?.classList.add(
        'blogs-footer__collapsible--minimized'
      )
    }
  }

  private attachFirstBanner(): void {
    if (!this.firstBannerTarget) return

    const headings = document.querySelectorAll('.blogs-show__contents h2')
    if (!headings) return
    const firstHeading = headings[0]
    if (!firstHeading) return
    this.attachBannerToBody(firstHeading, this.firstBannerTarget)
  }

  private attachSecondBanner(): void {
    if (!this.secondBannerTarget) return

    const headings = document.querySelectorAll('.blogs-show__contents h2')
    if (!headings) return
    const secondHeading = headings[1]
    if (!secondHeading) return
    this.attachBannerToBody(secondHeading, this.secondBannerTarget)
  }

  private attachBannerToBody(target: Element, body: HTMLElement): void {
    const bannerElement = document.createElement('div')
    bannerElement.style.height = '116px'
    bannerElement.innerHTML = body.innerHTML
    target.parentNode?.insertBefore(bannerElement, target)
  }
}
