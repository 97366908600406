import form from '~/components/molecules/form'

const {
  FormGroup,
  FormLabel,
  FormInput,
  FormControl,
  FormDescription,
  FormModalSelectorBtn,
  ErrorLabel,
  CheckBox,
  SelectBox,
  RadioBox,
  FormSubmit,
  TextArea,
  VForm,
} = form

export default {
  components: {
    FormGroup,
    FormLabel,
    FormInput,
    FormControl,
    FormDescription,
    FormModalSelectorBtn,
    ErrorLabel,
    CheckBox,
    SelectBox,
    RadioBox,
    FormSubmit,
    TextArea,
    VForm,
  },
}
