import { Controller } from 'stimulus'

export default class TradesOfflineController extends Controller {
  static targets: string[] = ['issueCodeForm']

  issueCodeFormTarget?: HTMLElement

  showCodeForm(e: Event): void {
    if (!(e.currentTarget instanceof HTMLButtonElement)) return

    e.currentTarget.classList.add('d-none')
    this.issueCodeFormTarget?.classList.remove('is-hidden')
  }
}
