import { AxiosResponse } from 'axios'
import axios, { AxiosRequestConfigWithAdditionals } from '~/lib/axios'
import { csrfToken } from '~/core/vars'

export const putCreditcard = (token: string): Promise<AxiosResponse<{}>> => {
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken()
  const fo = new FormData()
  fo.append('token', token)
  return axios({
    url: `/mypage/credit_card`,
    method: 'put',
    data: fo,
  })
}

export const deleteCreditcard = (): Promise<AxiosResponse<{}>> => {
  axios.defaults.headers.common['X-CSRF-Token'] = csrfToken()
  const authorization: HTMLFormElement | null =
    document.querySelector('#authorization')
  let url = '/mypage/credit_card'
  if (authorization) {
    axios.defaults.headers.common.Authorization = authorization.value
    url = '/api/personal/credit_card'
  }

  const interceptorConfig: AxiosRequestConfigWithAdditionals = {
    errorHandle: false,
  }
  return axios.delete(url, interceptorConfig)
}
