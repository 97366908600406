
import Vue from 'vue'

export default Vue.extend({
  props: {
    value: {
      type: String,
      default: '送信',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
})
