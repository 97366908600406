import { Controller } from 'stimulus'
import { getKuronekoShippingPrices } from '~/api/kuronekoShippingPrices'

export default class extends Controller {
  static targets = ['fromInput', 'toInput', 'invoiceKbInput', 'priceList']

  fromInputTarget!: HTMLSelectElement

  toInputTarget!: HTMLSelectElement

  invoiceKbInputTarget!: HTMLSelectElement

  priceListTarget!: HTMLSelectElement

  initialize(): void {
    this.update()
  }

  change(): void {
    this.update()
  }

  async update(): Promise<void> {
    const from = this.fromInputTarget.value
    const to = this.toInputTarget.value
    const invoiceKb = this.invoiceKbInputTarget.value
    this.priceListTarget.classList.add(
      'pages-easy-shipping-price__price-list--hidden'
    )
    const result = await getKuronekoShippingPrices(from, to, invoiceKb)
    this.renderPrices(result.data.kuroneko_shipping_prices)
  }

  renderPrices(prices: Model.KuronekoShippingPrice[]): void {
    const mImg = this.data.get('box-m-img')
    const sImg = this.data.get('box-s-img')
    this.priceListTarget.innerHTML = prices
      .map((item) => {
        const img = item.invoice_kb === 13 ? sImg : mImg
        return `<li
          class="pages-easy-shipping-price__price-cell"
          data-invoice-kb="${item.invoice_kb}"
        >
          <figure class="pages-easy-shipping-price__price-cell-figure">
            <img src='${img}'>
          </figure>
          <h2 class="pages-easy-shipping-price__price-cell-title">
            ${item.name}<br />
            ${item.size > 0 ? `${item.size}サイズ` : ''}
          </h2>
          <p class="pages-easy-shipping-price__price-cell-num">
            <small class="pages-easy-shipping-price__price-cell-unit">¥</small>
            ${item.price}
          </p>
        </li>`
      })
      .join('')
    this.priceListTarget.classList.remove(
      'pages-easy-shipping-price__price-list--hidden'
    )
  }
}
