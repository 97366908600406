import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image']

  declare readonly imageTarget: HTMLImageElement

  changePrizableSelect(e: Event) {
    if (e.target !== null && e.target instanceof HTMLSelectElement) {
      const select = e.target
      const img = this.imageTarget
      img.src = select.selectedOptions[0].dataset.src || ''
    }
  }

  changePrizableId(e: Event) {
    if (!(e.currentTarget instanceof HTMLSelectElement)) return
    const { currentTarget } = e

    const targetCouponId = currentTarget.dataset.targetCouponId || ''
    const targetOripaCardId = currentTarget.dataset.targetOripaCardId || ''
    const couponIdTarget = document.getElementById(targetCouponId)
    const oripaCardIdTarget = document.getElementById(targetOripaCardId)

    if (
      !currentTarget.value ||
      !(couponIdTarget instanceof HTMLInputElement) ||
      !(oripaCardIdTarget instanceof HTMLSelectElement)
    )
      return

    const isCoupon = currentTarget.value === 'Coupon'

    if (isCoupon) {
      this.inactiveField(oripaCardIdTarget)
      this.activeField(couponIdTarget)
      this.imageTarget.classList.add('d-none')
    } else {
      this.inactiveField(couponIdTarget)
      this.activeField(oripaCardIdTarget)
      this.imageTarget.classList.remove('d-none')
    }
  }

  activeField(target: HTMLElement) {
    target.setAttribute('name', 'oripa_prize[prizable_id]')
    target.setAttribute('required', 'required')
    target.classList.remove('d-none')
  }

  inactiveField(target: HTMLElement) {
    target.setAttribute('name', '')
    target.removeAttribute('required')
    target.classList.add('d-none')
  }
}
