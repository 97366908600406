import { Controller } from 'stimulus'
import showModal from '~/core/showModal'

export default class extends Controller {
  static targets = [
    'modalContent',
    'confirmInputDescription',
    'confirmModalPositiveButton',
    'confirmForm',
  ]

  modalContentTarget?: HTMLElement

  confirmInputDescriptionTarget?: HTMLElement

  confirmModalPositiveButtonTarget?: HTMLElement

  confirmFormTarget?: HTMLElement

  confirmModal(e: Event): void {
    e.preventDefault()
    if (!e.target) return

    if (
      !(this.confirmInputDescriptionTarget instanceof HTMLElement) ||
      !(this.confirmFormTarget instanceof HTMLElement) ||
      !(this.modalContentTarget instanceof HTMLElement) ||
      !(e.target instanceof HTMLAnchorElement)
    ) {
      throw new TypeError()
    }

    const { description } = e.target.dataset
    this.confirmInputDescriptionTarget.innerHTML = description || ''
    this.confirmFormTarget.setAttribute('action', e.target.href)

    const modalContent = document.createElement('div')
    modalContent.innerHTML = this.modalContentTarget?.innerHTML || ''

    if (!(modalContent.children[0] instanceof HTMLElement)) {
      throw new TypeError()
    }

    showModal(modalContent.children[0])
  }
}
