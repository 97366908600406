import { Controller } from 'stimulus'

export default class ItemsSearchController extends Controller {
  static targets: string[] = ['filterWrapper']

  filterWrapperTarget?: HTMLElement

  scrollToFilter(): void {
    if (!(this.filterWrapperTarget instanceof HTMLElement)) return
    const posY =
      this.filterWrapperTarget.getBoundingClientRect().top + window.scrollY

    window.scroll({
      top: posY,
      behavior: 'smooth',
    })
  }
}
