var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    _vm._l(_vm.template.fields, function (field, index) {
      return _c(
        "form-group",
        {
          key: _vm.generateCustomAttributeKey(field.name),
          staticClass: "form__group--column",
        },
        [
          _c("form-label", { attrs: { required: _vm.required } }, [
            _vm._v(_vm._s(field.label)),
          ]),
          _vm._v(" "),
          _c("input", {
            attrs: { type: "hidden", name: _vm.makeAtrrName(index) },
            domProps: { value: field.name },
          }),
          _vm._v(" "),
          _c(
            "form-control",
            [
              field.field_type === "selectbox"
                ? _c(
                    "select-box",
                    {
                      attrs: {
                        name: _vm.makeAttrValue(index),
                        "selector-id": "item_custom_attribute",
                        "selector-class": _vm.makeSelectedClass(field.name),
                        "selector-wrapper-class": _vm.selectorWrapperClass,
                      },
                      model: {
                        value: _vm.selectedAttribute[field.name],
                        callback: function ($$v) {
                          _vm.$set(_vm.selectedAttribute, field.name, $$v)
                        },
                        expression: "selectedAttribute[field.name]",
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("---")]),
                      _vm._v(" "),
                      _vm._l(field.options, function ({ label, value }) {
                        return _c(
                          "option",
                          { key: value, domProps: { value: value } },
                          [
                            _vm._v(
                              "\n          " + _vm._s(label) + "\n        "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                : field.field_type === "radio"
                ? _c("form-modal-selector-btn", {
                    attrs: { name: field.name, options: field.options },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }