
import Vue from 'vue'
import iconBusinessAccount from 'images/icon_business_white.svg'
import iconNoImage from 'images/icon-no-image.png'
import FormTemplateRenderer from '~/components/organisms/items/FormTemplateRenderer.vue'
import showGoodsSelector from '~/components/organisms/items/GoodsSelector'
import {
  getSearchFormTemplate,
  getSearchTrendKeywords,
} from '~/api/searchForms'
import HotTrendKeyword from '~/components/organisms/HotTrendKeyword.vue'
import { enLocale } from '~/lib/locale'

interface Data {
  goods: Model.Goods | undefined
  brand: Model.Brand | undefined
  series: Model.Series | undefined
  formTemplate: object | undefined
  keyword: string
  fromPrice: string
  toPrice: string
  includeInfo: boolean
  includeOripa: boolean
  status: string
  quality: string
  selectedSize: { size: string | undefined } | undefined
  hotTrendKeywords: string[]
}

interface Methods {
  showGoodsSelector(): void
  clearSearches(): void
  isSelectedSneaker(): boolean
  priceToRangeSlider(value: string, sliderMode: string): number
  rangeSliderToPrice(value: number, sliderMode: string): number
  setFromPriceFromRangeSlide(value: number): void
  setToPriceFromRangeSlide(value: number): void
  searchByHotTrendKeyword(keyword: string): void
  fetchHotTrendKeywords(
    goods_id: number | undefined,
    brand_id: number | undefined,
    series_id: number | undefined
  ): void
  isMobile(): boolean
  enLocale(): boolean
}

interface Computed {
  iconBusinessAccountUrl: string
  iconNoImageUrl: string
  sliderTrackBackground: string
  sliderMin: number
  sliderMax: number
  disablePriceBar: boolean
}

interface Props {
  keywordLabel: string
  keywordParam: string
  keywordPlaceholder: string
  owner: object
  goodsLabel: string
  selectedGoodsParam: {
    id: number
    name: string
    name_i18n: string
    inspection: string
  }
  brandLabel: string
  selectedBrandParam: { id: number; name: string; name_i18n: string }
  seriesLabel: string
  selectedSeriesParam: { id: number; name: string; name_i18n: string }
  selectedSizeParam: { [name: string]: string } | undefined
  priceLabel: string
  fromPriceParam: string
  toPriceParam: string
  qualityLabel: string
  qualityParam: string
  statusLabel: string
  statusParam: string
  includeDescriptionLabel: string
  includeInfoParam: boolean
  includeOriginalPackLabel: string
  includeOripaParam: boolean
  searchButtonLabel: string
  resetButtonLabel: string
  qualityOptions: { label: string; value: string }[]
  statusOptions: { label: string; value: string }[]
  actionPath: string
  isPopup: boolean
  sort: string
}

const SNEAKER_ID = 4 // item sneaker only
const RANGE_SLIDER_MAX = 32
export default Vue.extend<Data, Methods, Computed, Props>({
  components: {
    FormTemplateRenderer,
    HotTrendKeyword,
  },
  data() {
    return {
      goods: undefined,
      brand: undefined,
      series: undefined,
      formTemplate: undefined,
      keyword: '',
      fromPrice: '',
      toPrice: '',
      includeInfo: false,
      includeOripa: false,
      status: '',
      quality: '',
      selectedSize: undefined,
      hotTrendKeywords: [],
    }
  },
  async created() {
    const { data } = await getSearchFormTemplate()
    this.formTemplate = data.form_template

    this.keyword = this.keywordParam
    this.fromPrice = this.fromPriceParam
    this.toPrice = this.toPriceParam
    this.includeInfo = this.includeInfoParam
    this.includeOripa = this.includeOripaParam
    this.status = this.statusParam || ''
    this.quality = this.qualityParam || ''
    this.selectedSize = { size: this.selectedSizeParam?.size }
    if (this.selectedGoodsParam) {
      const inheritGoods: Model.Goods = {
        id: this.selectedGoodsParam.id,
        name: '',
        name_i18n: this.selectedGoodsParam.name_i18n,
        type: '',
        icon_path: '',
        children: [],
        inspection: this.selectedGoodsParam.inspection,
      }
      this.goods = inheritGoods
    }
    if (this.selectedBrandParam) {
      const inheritBrand: Model.Brand = {
        id: this.selectedBrandParam.id,
        name: '',
        name_i18n: this.selectedBrandParam.name_i18n,
      }
      this.brand = inheritBrand
    }
    if (this.selectedSeriesParam) {
      const inheritSeries: Model.Series = {
        id: this.selectedSeriesParam.id,
        name: '',
        name_i18n: this.selectedSeriesParam.name_i18n,
      }
      this.series = inheritSeries
    }
    this.fetchHotTrendKeywords(this.goods?.id, this.brand?.id, this.series?.id)
  },
  props: {
    keywordLabel: {
      type: String,
    },
    keywordParam: {
      type: String,
    },
    keywordPlaceholder: {
      type: String,
    },
    goodsLabel: {
      type: String,
    },
    selectedGoodsParam: {
      type: Object,
      default: null,
    },
    brandLabel: {
      type: String,
    },
    selectedBrandParam: {
      type: Object,
      default: null,
    },
    seriesLabel: {
      type: String,
    },
    selectedSeriesParam: {
      type: Object,
      default: null,
    },
    selectedSizeParam: {
      type: Object,
      default: null,
    },
    priceLabel: {
      type: String,
    },
    fromPriceParam: {
      type: String,
    },
    toPriceParam: {
      type: String,
    },
    qualityLabel: {
      type: String,
    },
    qualityParam: {
      type: String,
    },
    statusLabel: {
      type: String,
    },
    includeDescriptionLabel: {
      type: String,
    },
    includeInfoParam: {
      type: Boolean,
    },
    includeOriginalPackLabel: {
      type: String,
    },
    includeOripaParam: {
      type: Boolean,
    },
    searchButtonLabel: {
      type: String,
    },
    resetButtonLabel: {
      type: String,
    },
    qualityOptions: {
      type: Array,
    },
    statusOptions: {
      type: Array,
    },
    statusParam: {
      type: String,
    },
    actionPath: {
      type: String,
    },
    owner: {
      type: Object,
    },
    isPopup: {
      type: Boolean,
      default: false,
    },
    sort: {
      type: String,
    },
  },
  methods: {
    clearSearches() {
      this.keyword = ''
      this.fromPrice = ''
      this.toPrice = ''
      this.includeInfo = false
      this.includeOripa = false
      this.status = ''
      this.quality = ''
      this.goods = undefined
      this.series = undefined
      this.brand = undefined
      if (this.selectedSize) {
        this.selectedSize.size = ''
      }
      this.fetchHotTrendKeywords(undefined, undefined, undefined)
    },
    showGoodsSelector() {
      showGoodsSelector((_s) => {
        this.goods = _s.goods
        this.brand = _s.brand
        this.series = _s.series
        this.fetchHotTrendKeywords(
          this.goods?.id,
          this.brand?.id,
          this.series?.id
        )
      })
    },
    isSelectedSneaker() {
      return this.goods?.id === SNEAKER_ID
    },
    priceToRangeSlider(value, sliderMode) {
      const valueNumber: number = parseInt(value, 10)
      if (
        sliderMode === 'min' &&
        (Number.isNaN(valueNumber) || valueNumber <= 0)
      )
        return 0
      if (
        sliderMode === 'max' &&
        (Number.isNaN(valueNumber) || valueNumber >= 500000)
      )
        return RANGE_SLIDER_MAX

      switch (true) {
        case valueNumber <= 0:
          return 0
        case valueNumber <= 5000:
          return valueNumber / 500
        case valueNumber <= 10000:
          return 10 + (valueNumber - 5000) / 1000
        case valueNumber <= 50000:
          return 15 + (valueNumber - 10000) / 5000
        case valueNumber <= 100000:
          return 23 + (valueNumber - 50000) / 10000
        case valueNumber <= 500000:
          return 28 + (valueNumber - 100000) / 100000
        default:
          return 0
      }
    },
    rangeSliderToPrice(value, sliderMode) {
      if (sliderMode === 'min' && value === 0) return 0
      if (sliderMode === 'max' && value === RANGE_SLIDER_MAX) return 500000

      switch (true) {
        case value <= 0:
          return 0
        case value <= 10:
          return value * 500
        case value <= 15:
          return 5000 + (value - 10) * 1000
        case value <= 23:
          return 10000 + (value - 15) * 5000
        case value <= 28:
          return 50000 + (value - 23) * 10000
        case value <= RANGE_SLIDER_MAX:
          return 100000 + (value - 28) * 100000
        default:
          return 0
      }
    },
    setFromPriceFromRangeSlide(value) {
      this.fromPrice = value <= 0 ? '' : value.toString()
    },
    setToPriceFromRangeSlide(value) {
      this.toPrice = value >= 500000 ? '' : value.toString()
    },
    searchByHotTrendKeyword(keyword) {
      this.keyword = keyword

      this.$nextTick(() => {
        if (
          !this.$refs.searchForm ||
          !(this.$refs.searchForm instanceof HTMLFormElement)
        )
          return
        this.$refs.searchForm.submit()
      })
    },
    async fetchHotTrendKeywords(goods_id, brand_id, series_id) {
      const { data } = await getSearchTrendKeywords(
        goods_id,
        brand_id,
        series_id
      )
      const keywords = data.search_trends.map(
        (search_trend) => search_trend.keyword
      )

      this.hotTrendKeywords = this.isMobile() ? keywords.slice(0, 10) : keywords
    },
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    },
    enLocale() {
      return enLocale()
    },
  },
  computed: {
    iconBusinessAccountUrl(): string {
      return iconBusinessAccount
    },
    iconNoImageUrl(): string {
      return iconNoImage
    },
    sliderMin: {
      get(): number {
        return this.priceToRangeSlider(this.fromPrice, 'min')
      },
      set(value: number): void {
        let toPriceNumber = parseInt(this.toPrice, 10)
        toPriceNumber = Number.isNaN(toPriceNumber) ? 500000 : toPriceNumber
        const fromPriceNumber = this.rangeSliderToPrice(value, 'min')

        if (fromPriceNumber > toPriceNumber) {
          this.setToPriceFromRangeSlide(fromPriceNumber)
        }

        this.setFromPriceFromRangeSlide(fromPriceNumber)
      },
    },
    sliderMax: {
      get(): number {
        return this.priceToRangeSlider(this.toPrice, 'max')
      },
      set(value: number): void {
        let fromPriceNumber = parseInt(this.fromPrice, 10)
        fromPriceNumber = Number.isNaN(fromPriceNumber) ? 0 : fromPriceNumber

        const toPriceNumber = this.rangeSliderToPrice(value, 'max')
        if (toPriceNumber < fromPriceNumber) {
          this.setFromPriceFromRangeSlide(toPriceNumber)
        }

        this.setToPriceFromRangeSlide(toPriceNumber)
      },
    },
    sliderTrackBackground(): string {
      if (this.disablePriceBar) return '#c7c7c7'
      const percent1 = (this.sliderMin / RANGE_SLIDER_MAX) * 100
      const percent2 = (this.sliderMax / RANGE_SLIDER_MAX) * 100
      return `linear-gradient(to right, #c7c7c7 ${percent1}% , #444444 ${percent1}% , #444444 ${percent2}%, #c7c7c7 ${percent2}%)`
    },
    disablePriceBar(): boolean {
      return (
        (this.fromPrice === '' || this.fromPrice === null) &&
        (this.toPrice === '' || this.toPrice === null)
      )
    },
  },
})
