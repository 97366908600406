const initClickEventSender = (): void => {
  const elBtn = document.querySelector('.js-ga-click-event')
  if (!elBtn) return
  elBtn.addEventListener('click', (e: Event) => {
    const target = e.currentTarget
    if (!(target instanceof HTMLElement)) return
    const category = target.dataset.gaCategory
    const action = target.dataset.gaAction
    const label = target.dataset.gaLabel
    ga('send', 'event', category, action, label)
  })
}

const initGA = (): void => {
  initClickEventSender()
}
export default initGA
