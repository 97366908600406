import { Controller } from 'stimulus'
import showModal from '~/core/showModal'

export default class QrCodeModalController extends Controller {
  static targets: string[] = ['element']

  elementTarget?: HTMLElement

  connect() {
    document.addEventListener('closeQrModalTrigger', () => {
      localStorage.hideQrCodeModal = true
    })
  }

  initialize(): void {
    if (localStorage.hideQrCodeModal) return
    if (!this.elementTarget) return

    showModal(this.elementTarget, 'fixed-size', 'closeQrModalTrigger')
  }

  copyClipboard(event: Event): void {
    event.preventDefault()
    const target = event.currentTarget
    if (!(target instanceof HTMLElement)) return
    const { invitationCode } = target.dataset
    if (!invitationCode) return
    navigator.clipboard.writeText(invitationCode)
  }
}
