import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['element']

  elementTarget?: HTMLElement

  show(): void {
    if (!this.elementTarget) throw new TypeError('loader elm is null')
    this.elementTarget.classList.add('loader--shown')
  }

  hide(): void {
    if (!this.elementTarget) throw new TypeError('loader elm is null')
    this.elementTarget.classList.remove('loader--shown')
  }
}
