import { Controller } from 'stimulus'
import showModal from '~/core/showModal'

export default class extends Controller {
  async show(): Promise<void> {
    const body = this.data.get('body')
    if (typeof body !== 'string') return
    const el = document.createElement('div')
    el.innerHTML = body
    showModal(el)
  }
}
