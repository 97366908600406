import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'
import { csrfToken } from '~/core/vars'

export const uploadImage = (
  file: File
): Promise<AxiosResponse<Api.Administrators.UploadImage.Response>> => {
  const token = csrfToken()
  const formData = new FormData()
  if (!token) throw TypeError('null csrf token')

  formData.append('upload_image[image_attributes][file]', file)
  formData.append('authenticity_token', token)

  return axios.post('/administration/upload_images', formData, {
    headers: {
      'content-type': 'multipart/form-data',
    },
  })
}
