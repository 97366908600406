var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "shortcodes-blogs-search-items" }, [
    _c("div", { staticClass: "shortcodes-blogs-search-items__container" }, [
      _c("div", { staticClass: "shortcodes-blogs-search-items__title" }, [
        _c("i", { staticClass: "jirafficon jirafficon-loupe" }),
        _vm._v(_vm._s(_vm.title) + "\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "shortcodes-blogs-search-items__results-container" },
        [
          _vm.created && _vm.items.length < 1
            ? _c(
                "div",
                {
                  staticClass:
                    "shortcodes-blogs-search-items__notfound-container",
                },
                [
                  _c("div", {
                    staticClass:
                      "shortcodes-blogs-search-items__notfound-image",
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.noResultsLabel))]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "shortcodes-blogs-search-items__items-container item-list",
            },
            _vm._l(_vm.items, function (item) {
              return _c(
                "div",
                {
                  key: item.displayed_id,
                  staticClass:
                    "item-list__box item-list__box--shortcode-blog-search-items",
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "item-list__link",
                      attrs: { href: _vm.itemPath(item) },
                    },
                    [
                      _c("figure", { staticClass: "item-list__thumbnail" }, [
                        _c(
                          "div",
                          { staticClass: "item-list__thumbnail-content" },
                          [
                            _c("img", {
                              staticClass: "lozad lazyload lazyload--wrap",
                              attrs: {
                                height: "auto",
                                width: "100%",
                                alt: item.name,
                                "data-src": _vm.thumbnailUrl(item),
                                src: _vm.thumbnailUrl(item),
                                "data-loaded": "true",
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        item.status != "presented"
                          ? _c("div", { staticClass: "item-list__sold-icon" })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-list__item-name" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                      _vm._v(" "),
                      _c("ul", { staticClass: "item-list__price-box" }, [
                        _c(
                          "li",
                          { staticClass: "item-list__price-box--price" },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.numberToCurrency(item.price)) +
                                "\n              "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "li",
                          {
                            staticClass:
                              "item-list__price-box--favorite-number",
                          },
                          [
                            _c("img", {
                              attrs: { src: _vm.iconStarImagePath },
                            }),
                            _vm._v(
                              _vm._s(item.check_count) + "\n              "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      item.owner.user_type === "business"
                        ? _c("div", { staticClass: "item-list__badge" }, [
                            _c("img", {
                              attrs: { src: _vm.iconBadgeItemShopPath },
                            }),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
      _vm._v(" "),
      _vm.created
        ? _c(
            "div",
            {
              staticClass:
                "shortcodes-blogs-search-items__more-button-container",
            },
            [
              _c(
                "a",
                {
                  staticClass: "shortcodes-blogs-search-items__more-button",
                  attrs: { href: _vm.moreButtonPath() },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "shortcodes-blogs-search-items__more-button-label",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.moreButtonLabel()) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("i", {
                    staticClass:
                      "shortcodes-blogs-search-items__more-button-icon jirafficon jirafficon-arrow-right inline-icon",
                  }),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }