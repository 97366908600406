import { Controller } from 'stimulus'
import { formatJpPrice } from '~/lib/utility'

export default class extends Controller {
  static targets = ['charge', 'neededSales']

  chargeTarget!: HTMLInputElement

  neededSalesTarget!: HTMLElement

  payoutsChange(e: Event): void {
    if (
      !(e.target instanceof HTMLInputElement) ||
      !(this.chargeTarget instanceof HTMLInputElement) ||
      !(this.neededSalesTarget instanceof HTMLElement)
    ) {
      throw new TypeError()
    }

    let payouts = parseInt(e.target.value, 10)

    if (Number.isNaN(payouts)) payouts = 0

    const charge = parseInt(this.chargeTarget.value, 10)

    this.neededSalesTarget.textContent = formatJpPrice(payouts + charge)
  }
}
