
import Vue from 'vue'
import ModalSelector from '~/components/molecules/form/ModalSelector.vue'
import ModalController from '~/controllers/modal_controller'
import { findController } from '~/lib/stimulus'

interface Option {
  value: string
  label: string
}

interface Data {
  modal?: ModalController
  selectedOption?: Option
}

interface Methods {
  onSelected(value: Option): void
  showSelectorModal(): void
  updateValue(value: Option): void
}

interface Computed {
  additionalClassName: string
  selectedLabel: string
}

interface Props {
  name: string
  placeholder: string
  error: boolean
  options: Option[]
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      modal: undefined,
      selectedOption: undefined,
    }
  },
  created() {
    this.modal = findController<ModalController>('modal')
  },
  computed: {
    additionalClassName(): string {
      return this.$props.error ? 'form__input--field-error' : ''
    },
    selectedLabel(): string {
      if (this.selectedOption) {
        return this.selectedOption.label
      }
      return this.$props.placeholder
    },
  },
  methods: {
    onSelected(value): void {
      this.selectedOption = value
      this.updateValue(value)
      this.modal?.close()
    },
    showSelectorModal(): void {
      if (!this.modal) throw new TypeError()
      this.modal.showWithVue(ModalSelector, {
        propsData: {
          onSelected: this.onSelected,
          options: this.$props.options,
          selectedOption: this.selectedOption,
        },
      })
    },
    updateValue(value): void {
      this.$emit('input', value)
      this.$emit('change', value)
    },
  },
  props: {
    name: {
      type: String,
    },
    placeholder: {
      type: String,
      default: '---',
    },
    error: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
})
