import { Controller } from 'stimulus'

export default class extends Controller {
  onChange(e: { target: { value: string } }) {
    const selectValue = e.target.value
    const inputElement = document.querySelector<HTMLInputElement>(
      '#forms_search_items_sort'
    )
    const form = document.querySelector<HTMLFormElement>(
      '#item_advanced_search'
    )
    if (!inputElement || !form) return

    inputElement.value = selectValue
    form.submit()
  }
}
