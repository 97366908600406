var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: "form__input--select-wrapper " + _vm.selectorWrapperClass },
    [
      _c("v-select", {
        class:
          "form__input " + _vm.additionalClassName() + "" + _vm.selectorClass,
        attrs: {
          multiple: _vm.multiple,
          taggable: _vm.taggable,
          options: _vm.options,
          reduce: (option) => option.value,
          id: _vm.selectorId,
        },
        on: {
          input: _vm.updateValue,
          focus: function ($event) {
            return _vm.$emit("focus", $event)
          },
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
        },
        model: {
          value: _vm.input.selected,
          callback: function ($$v) {
            _vm.$set(_vm.input, "selected", $$v)
          },
          expression: "input.selected",
        },
      }),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.input.selected,
            expression: "input.selected",
          },
        ],
        attrs: { type: "hidden", name: _vm.name },
        domProps: { value: _vm.input.selected },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.input, "selected", $event.target.value)
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }