
import Vue from 'vue'
import jusPurchasedImage from 'images/trade/purchased_bold.svg'
import { findController } from '~/lib/stimulus'
import ModalController from '~/controllers/modal_controller'

interface Data {
  performanceType: string
  point: number
}

interface Methods {
  shareTwitter(): void
  handleClickCloseButton(): void
}

interface Computed {
  productImageSrc: string
  jutPurchasedImageUrl: string
}

interface Props {
  isAppAccess: boolean
  currentUserIsIos: boolean
  shareUrl: string
  subText: string
  productImageUrl: string
}

export default Vue.extend<Data, Methods, Computed, Props>({
  data() {
    return {
      performanceType: '',
      point: 0,
    }
  },
  props: {
    isAppAccess: {
      type: Boolean,
    },
    currentUserIsIos: {
      type: Boolean,
    },
    shareUrl: {
      type: String,
    },
    productImageUrl: {
      type: String,
    },
    subText: {
      type: String,
    },
  },
  methods: {
    shareTwitter(): void {
      const { shareUrl, isAppAccess, currentUserIsIos } = this.$props
      if (isAppAccess && currentUserIsIos) {
        window.location.href = shareUrl
      } else {
        window.open(shareUrl)
      }
    },
    handleClickCloseButton(): void {
      const modal = findController<ModalController>('modal')
      modal?.close()
    },
  },
  computed: {
    productImageSrc(): string {
      return this.$props.productImageUrl
    },
    jutPurchasedImageUrl(): string {
      return jusPurchasedImage
    },
  },
  filters: {
    numberFormat(point: number): string {
      return point.toLocaleString()
    },
  },
})
