var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    staticClass: "form__submit-btn",
    attrs: { type: "submit", disabled: _vm.disabled },
    domProps: { value: _vm.value },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }