import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'

export const getProductSuggestions = (
  text: string,
  seriesId: number | undefined = undefined,
  page: number | undefined = undefined
): Promise<AxiosResponse<Api.Product.Suggestions.Response>> => {
  return axios.get('/api/products/suggestions', {
    params: {
      'search_products[keyword]': text,
      'search_products[series_id]': seriesId,
      page,
    },
  })
}
