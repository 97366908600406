var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.messages.length > 0
    ? _c(
        "div",
        {
          staticClass: "form__field-error",
          attrs: { role: "alert", "aria-label": "Error Message" },
        },
        _vm._l(_vm.messages, function (message) {
          return _c("div", { key: message }, [
            _vm._v("\n    " + _vm._s(message) + "\n  "),
          ])
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }