import { Controller } from 'stimulus'
import { getTradesShippingCost } from '~/api/trades/shippingCost'

export default class ShippingCostFormController extends Controller {
  static targets = ['price']

  priceTarget!: HTMLElement

  async change(e: InputEvent): Promise<void> {
    if (!(e.target instanceof HTMLSelectElement)) throw new TypeError()
    const size = e.target.value
    if (!size) {
      this.priceTarget.innerHTML = '---'
      return
    }
    const tradeId = this.data.get('trade-id')
    if (!tradeId) throw new TypeError()

    const result = await getTradesShippingCost(tradeId, size)
    if (!result || result.status >= 400) {
      this.priceTarget.innerHTML = '---'
      return
    }
    this.priceTarget.innerHTML = result.data.shipping_cost
  }
}
