
import Vue from 'vue'

interface SelectableCell<Value = unknown> {
  label: string
  isLeaf: boolean
  value: Value
}

interface HeadingCell {
  heading: true
  label: string
}

interface Sheet<Value = unknown> {
  title: string
  list: Array<SelectableCell<Value> | HeadingCell>
}

interface Data<Value = unknown> {
  sheets: Sheet<Value>[]
  prevIndex: number
  currentIndex: number
  animationType: 'forward' | 'back'
}

interface Methods<Value = unknown> {
  pushSheet(sheet: Sheet<Value>): void
  back(): void
  selected(cell: SelectableCell<Value>, e: Event): void
}

interface Computed<Value = unknown> {
  currentSheet: Sheet<Value>
  isBackable: boolean
}

interface Props<Value = unknown> {
  onSelected(
    cell: SelectableCell<Value>,
    push: (sheet: Sheet<Value>) => void
  ): void
  onBack?(): void
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    onSelected: {
      type: Function,
      required: true,
    },
    onBack: {
      type: Function,
      required: false,
    },
  },
  data(): Data {
    return {
      animationType: 'forward',
      prevIndex: -1,
      currentIndex: -1,
      sheets: [],
    }
  },
  computed: {
    currentSheet(): Sheet {
      return this.sheets[this.currentIndex]
    },
    isBackable(): boolean {
      return this.currentIndex >= 1
    },
  },
  methods: {
    pushSheet(sheet: Sheet): void {
      this.animationType = 'forward'
      this.prevIndex = this.currentIndex
      this.sheets.push(sheet)
      this.currentIndex += 1
    },
    back(): void {
      this.animationType = 'back'
      this.prevIndex = this.currentIndex
      this.currentIndex -= 1
      this.sheets.pop()
      if (this.$props.onBack) {
        this.$props.onBack(this.currentSheet)
      }
    },
    selected(cell: SelectableCell, e: Event): void {
      e.preventDefault()
      this.onSelected(cell, this.pushSheet)
    },
  },
})
