import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabPanel', 'pager']

  tabTargets?: HTMLElement[]

  tabPanelTargets?: HTMLElement[]

  pagerTargets?: HTMLElement[]

  connect() {
    this.showTab(0)
  }

  handleTabClick(event: { target: { dataset: { index: string } } }) {
    const index = Number(event.target.dataset.index)
    this.showTab(index)
  }

  showTab(index: number) {
    this.setActiveClass(this.tabTargets, index, 'tab-list__tab--active')
    this.setActiveClass(this.tabPanelTargets, index, 'tab-list__panel--active')
  }

  private setActiveClass(
    targets: HTMLElement[] | undefined,
    index: number,
    activeClass: string
  ): void {
    targets?.forEach((tab, tabIndex) => {
      if (tabIndex === index) {
        tab.classList.add(activeClass)
      } else {
        tab.classList.remove(activeClass)
      }
    })
  }
}
