
import Vue from 'vue'

interface Data {}

interface Option {
  value: string
  label: string
}

interface Methods {
  isSelected(option: Option): boolean
  checkboxClassName(option: Option): string
}

interface Computed {}

interface Props {
  selectedOption?: Option
  onSelected(value: string, name: string): void
  options: Option[]
}

export default Vue.extend<Data, Methods, Computed, Props>({
  props: {
    onSelected: {
      type: Function,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: Object,
    },
  },
  methods: {
    isSelected(option: Option): boolean {
      return option.value === this.$props.selectedOption?.value
    },
    checkboxClassName(option: Option): string {
      return this.isSelected(option)
        ? 'form__checkbox form__checkbox--readonly-checked'
        : 'form__checkbox form__checkbox--readonly-checked form__checkbox--readonly-checked-disabled'
    },
  },
})
