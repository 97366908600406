
import noimage from 'images/noimage-card.svg'
import iconBadgeItemShop from 'images/icon_badge_item_shop.png'
import iconStarOn from 'images/icon_star_on.png'
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { searchItems } from '~/api/items/search'
import numberFormattable from '~/components/mixins/numberFormattable'
import paramsSerializer from '~/api/paramsSerializer'

interface Data {
  items: Model.Item[]
  chunkedItems: Model.Item[][]
  created: boolean
}

interface Methods {
  moreButtonLabel(): string
  moreButtonPath(): string
  thumbnailUrl(item: Model.Item): string
  itemPath(item: Model.Item): string
}

interface Computed {
  iconStarImagePath: string
  iconBadgeItemShopPath: string
}

interface CustomAttribute {
  name: string
  values: string[]
}

interface Props {
  searchParameters: {
    forms_search_items: {
      keyword: string
      series_id: string
      brand_id: string
      goods_id: string
      from_price: string
      to_price: string
      sort: string
      page: string
    }
    item: {
      custom_attributes: CustomAttribute[]
    }
  }
  title: string
  moreLabel: string
  noResultsLabel: string
  otherKeywordLabel: string
}

export default Vue.extend<Data, Methods, Computed, Props>({
  mixins: [numberFormattable],
  data() {
    return {
      items: [],
      chunkedItems: [],
      created: false,
    }
  },
  props: {
    searchParameters: { type: Object },
    title: { type: String },
    moreLabel: { type: String },
    noResultsLabel: { type: String },
    otherKeywordLabel: { type: String },
  },
  async created() {
    searchItems(this.$props.searchParameters)
      .then((response: AxiosResponse<Api.Items.Search.Response>) => {
        this.items = response.data.items.slice(0, 8)
      })
      .finally(() => {
        this.created = true
      })
  },
  computed: {
    iconStarImagePath(): string {
      return iconStarOn
    },
    iconBadgeItemShopPath(): string {
      return iconBadgeItemShop
    },
  },
  methods: {
    moreButtonLabel(): string {
      if (this.items.length > 0) {
        return this.$props.moreLabel
      }
      return this.$props.otherKeywordLabel
    },
    moreButtonPath(): string {
      return `/items/search?${paramsSerializer(this.$props.searchParameters)}`
    },
    thumbnailUrl(item: Model.Item): string {
      return item.thumbnail_url || noimage
    },
    itemPath(item: Model.Item): string {
      return `/items/${item.displayed_id}`
    },
  },
})
