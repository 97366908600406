import { Controller } from 'stimulus'
import { deleteCreditcard } from '~/api/mypage/creditcard'
import { findController } from '~/lib/stimulus'
import AlertController from './alert_controller'

export default class extends Controller {
  static targets = ['deleteBtn']

  holdernameInputTarget?: HTMLInputElement

  cardnumberInputTarget?: HTMLInputElement

  expireMonthInputTarget?: HTMLSelectElement

  expireYearInputTarget?: HTMLSelectElement

  securitycodeInputTarget?: HTMLInputElement

  submitTarget?: HTMLInputElement

  deleteBtnTarget?: HTMLElement

  onPressedDelete(e: Event): boolean {
    e.preventDefault()
    if (!(this.deleteBtnTarget instanceof HTMLElement)) {
      throw new TypeError()
    }
    const url = this.data.get('url')
    this.deleteBtnTarget.setAttribute('disabled', 'disabled')
    const defaultButtonText = this.deleteBtnTarget.innerText
    this.deleteBtnTarget.innerText = '削除しています…'
    deleteCreditcard()
      .then(() => {
        if (url === '/hoge') {
          window.location.assign(`${url}/edit`)
        } else if (url === '/api/personal/credit_card') {
          // FIXME: ちゃんと jest で test する
          window.location.href = '/mypage'
        } else {
          // FIXME: ちゃんと jest で test する
          window.location.reload()
        }

        // send messages for ios
        if (
          window.webkit?.messageHandlers?.pageReload?.postMessage !== undefined
        ) {
          window.webkit.messageHandlers.pageReload.postMessage(
            'DeleteCardSuccess'
          )
        }
      })
      .catch((error) => {
        if (!(this.deleteBtnTarget instanceof HTMLButtonElement)) {
          throw new TypeError()
        }

        const alert = findController<AlertController>('alert')

        if (!alert) {
          throw new Error('alertが見つかりません')
        }

        const message =
          error.response.status === 403
            ? error.response.data.message
            : 'カード情報の削除に失敗しました'

        alert.show({ title: message })

        this.deleteBtnTarget.removeAttribute('disabled')
        this.deleteBtnTarget.innerText = defaultButtonText
      })
    return false
  }
}
