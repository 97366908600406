var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "v-select",
        {
          staticClass: "vSelect",
          attrs: {
            filterable: false,
            options: _vm.options,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            multiple: _vm.multiple,
          },
          on: { input: _vm.inputValue, search: _vm.onSearch },
          scopedSlots: _vm._u([
            {
              key: "option",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "dCenter" }, [
                    _vm._v("\n        " + _vm._s(option.name) + "\n      "),
                  ]),
                ]
              },
            },
            {
              key: "selected-option",
              fn: function (option) {
                return [
                  _c("div", { staticClass: "dCenter" }, [
                    _vm._v("\n        " + _vm._s(option.name) + "\n      "),
                  ]),
                ]
              },
            },
          ]),
          model: {
            value: _vm.inputSelected,
            callback: function ($$v) {
              _vm.inputSelected = $$v
            },
            expression: "inputSelected",
          },
        },
        [
          _c("template", { slot: "no-options" }, [
            _vm._v("\n      " + _vm._s(_vm.noOption) + "\n    "),
          ]),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }