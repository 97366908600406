import { Controller } from 'stimulus'
import { on as noScrollOn, off as noScrollOff } from 'no-scroll'
import { debounce } from 'lodash'

interface Options {
  positiveBtnLabel?: string
  negativeBtnLabel?: string
  onClickPositive?: () => void
  onClickNegative?: () => void
  title?: string
  description?: string
}
export default class AlertController extends Controller {
  static targets = ['element', 'contents', 'negativeButton', 'positiveButton']

  elementTarget?: HTMLElement

  contentsTarget?: HTMLElement

  positiveButtonTarget?: HTMLElement

  negativeButtonTarget?: HTMLElement

  negativeCallback: () => void = () => {
    this.close()
  }

  positiveCallback: () => void = () => {}

  initialize(): void {
    if (!this.elementTarget) return

    this.elementTarget.classList.add('alert--hidden')
    this.elementTarget.addEventListener('animationend', () => {
      this.onAnimationEnd()
    })
  }

  show(options: Options): void {
    if (!this.negativeButtonTarget) return
    const el = document.createElement('div')
    const body = [
      options.title ? `<h2 class="alert__title">${options.title}</h2>` : '',
      options.description
        ? `<p class="alert__description">${options.description}</p>`
        : '',
    ].join('')
    el.innerHTML = body

    this.setPositiveButtonLabel(options.positiveBtnLabel || 'OK')
    this.setNegativeButtonLabel(options.negativeBtnLabel || '')
    this.setPositiveCallback(options.onClickPositive || this.close)
    this.setNegativeCallback(options.onClickNegative || this.close)

    this.negativeButtonTarget.classList.add('d-none')
    if (options.negativeBtnLabel) {
      this.negativeButtonTarget.classList.remove('d-none')
    }

    this.showWithElement(el)
  }

  showWithElement(element: Element): void {
    if (!this.elementTarget || !this.contentsTarget) return
    this.elementTarget.classList.remove('alert--hidden')
    this.elementTarget.classList.add('alert--shown')
    this.clearAlert()
    noScrollOn()
    if (element) {
      this.contentsTarget.appendChild(element)
    }
  }

  close(): void {
    if (!this.elementTarget) return
    this.elementTarget.classList.remove('alert--shown')
    this.elementTarget.classList.add('alert--removing')
    noScrollOff()
  }

  clearAlert(): void {
    if (!this.contentsTarget) return
    this.contentsTarget.innerHTML = ''
  }

  onAnimationEnd(): void {
    if (!this.elementTarget) return
    if (this.elementTarget.classList.contains('alert--removing')) {
      this.elementTarget.classList.add('alert--hidden')
      this.elementTarget.classList.remove('alert--removing')
    }
  }

  onClickPositive = debounce(
    () => {
      this.positiveCallback()
    },
    1000,
    { leading: true }
  )

  onClickNegative = debounce(
    () => {
      this.negativeCallback()
    },
    1000,
    { leading: true }
  )

  setPositiveCallback(callback: () => void): void {
    this.positiveCallback = callback
  }

  setNegativeCallback(callback: () => void): void {
    this.negativeCallback = callback
  }

  setPositiveButtonLabel(buttonLabel: string): void {
    if (!this.positiveButtonTarget) return
    this.positiveButtonTarget.innerText = buttonLabel
  }

  setNegativeButtonLabel(buttonLabel: string): void {
    if (!this.negativeButtonTarget) return
    this.negativeButtonTarget.innerText = buttonLabel
  }
}
