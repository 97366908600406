import { Controller } from 'stimulus'

// TODO: テスト DORPZONE にする
export default class extends Controller {
  static targets = [
    'thumb',
    'thumbImg',
    'thumbNone',
    'dropzone',
    'deleteFlgInput',
    'imageArea',
    'fileInput',
  ]

  thumbTarget?: HTMLElement

  thumbImgTarget?: HTMLElement

  thumbNoneTarget?: HTMLElement

  dropzoneTarget?: HTMLElement

  imageAreaTarget?: HTMLElement

  deleteFlgInputTarget?: HTMLInputElement

  fileInputTarget?: HTMLInputElement

  reader?: FileReader

  onDragOver(e: Event): void {
    e.stopPropagation()
    e.preventDefault()
  }

  onDrop(e: DragEvent): void {
    e.stopPropagation()
    e.preventDefault()

    const { dataTransfer } = e
    if (!dataTransfer) {
      return
    }
    const { files } = dataTransfer
    if (!files) {
      return
    }
    if (!(this.fileInputTarget instanceof HTMLInputElement)) {
      throw new TypeError()
    }
    this.fileInputTarget.files = files
    this.changeFile(files[0])
  }

  onChange(e: Event): void {
    const { target } = e
    if (!(target instanceof HTMLInputElement)) {
      throw new TypeError()
    }
    if (!target.files) {
      return
    }
    const file = target.files[0]
    if (!(file instanceof File)) {
      throw new TypeError()
    }
    this.changeFile(file)
  }

  changeFile(file: File): void {
    if (!file.type.match('image.*')) {
      return
    }
    if (!(this.deleteFlgInputTarget instanceof HTMLInputElement)) {
      throw new TypeError()
    }
    this.deleteFlgInputTarget.value = ''
    this.reader = new FileReader()
    this.reader.onload = this.onLoadImg
    this.reader.readAsDataURL(file)
  }

  deleteImage(): void {
    if (!(this.imageAreaTarget instanceof HTMLElement)) {
      throw new TypeError()
    }
    if (!(this.dropzoneTarget instanceof HTMLElement)) {
      throw new TypeError()
    }
    if (!(this.deleteFlgInputTarget instanceof HTMLInputElement)) {
      throw new TypeError()
    }
    this.imageAreaTarget.classList.add('is-hidden')
    this.dropzoneTarget.classList.remove('is-hidden')
    this.deleteFlgInputTarget.value = 'true'
  }

  onLoadImg = (): void => {
    if (!(this.reader instanceof FileReader)) {
      throw new TypeError()
    }
    if (!(this.thumbImgTarget instanceof HTMLElement)) {
      throw new TypeError()
    }
    if (!(this.thumbTarget instanceof HTMLElement)) {
      throw new TypeError()
    }
    if (!(this.thumbNoneTarget instanceof HTMLElement)) {
      throw new TypeError()
    }
    if (!this.reader.result) {
      return
    }
    this.thumbImgTarget.classList.remove('is-hidden')
    this.thumbNoneTarget.classList.add('is-hidden')
    this.thumbImgTarget.setAttribute('src', this.reader.result.toString())
  }
}
