import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'

export const getSearchFormTemplate = (): Promise<
  AxiosResponse<Api.SearchForms.Response>
> => {
  return axios.get('/api/search_forms')
}

export const getSearchTrendKeywords = (
  goods_id: number | undefined = undefined,
  brand_id: number | undefined = undefined,
  series_id: number | undefined = undefined
): Promise<AxiosResponse<Api.SearchTrendKeywords.Response>> => {
  return axios.get('/api/search_trends', {
    params: { goods_id, brand_id, series_id },
  })
}
