import { findController } from '~/lib/stimulus'
import LoadingModalController from '~/controllers/loading_modal_controller'

export function showLoading(): void {
  const modal = findController<LoadingModalController>('loading-modal')
  if (!modal) return
  modal.show()
}

export function hideLoading(): void {
  const modal = findController<LoadingModalController>('loading-modal')
  if (!modal) return
  modal.hide()
}
