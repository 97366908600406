var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "form" }, [
    _c("header", { class: _vm.$style.Header }, [
      _c("h2", [_vm._v("選択してください")]),
    ]),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.options, function (option) {
        return _c(
          "li",
          {
            key: option.value,
            class: _vm.$style.Cell,
            attrs: { id: `posting_method_cell_${option.value}` },
            on: { click: () => _vm.onSelected(option) },
          },
          [
            _c(
              "div",
              { class: _vm.$style.CellInner, attrs: { role: "button" } },
              [
                _vm._v("\n        " + _vm._s(option.label) + "\n        "),
                _c("div", { class: _vm.checkboxClassName(option) }),
              ]
            ),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }