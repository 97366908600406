
import Vue from 'vue'

export default Vue.extend({
  props: {
    required: {
      type: Boolean,
      default: false,
    },
  },
})
