// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "._1vqhQ2J7AU_g4NxRX-gHtE{position:-webkit-sticky;position:sticky;top:0;left:0;background:#34004f;padding:8px;box-sizing:border-box;color:#fff;z-index:1}._15LcCelQLtVMDELm-qDgug{border-bottom:solid 1px #c7c7c7;cursor:pointer;min-height:40px}._15LcCelQLtVMDELm-qDgug:hover{background:#fafafa}._3m9TXQGwdsxCLFk4UbbjM{padding-left:12px;display:flex;justify-content:space-between;align-items:center}._3mqlZY4DQuNM69VATlVFci{margin:0 12px}._3Y2FV-7dHgWKlSFinY1pPW{max-width:100%;height:auto}\n", ""]);
// Exports
exports.locals = {
	"Header": "_1vqhQ2J7AU_g4NxRX-gHtE",
	"Cell": "_15LcCelQLtVMDELm-qDgug",
	"CellInner": "_3m9TXQGwdsxCLFk4UbbjM",
	"TagList": "_3mqlZY4DQuNM69VATlVFci",
	"PriceImg": "_3Y2FV-7dHgWKlSFinY1pPW"
};
module.exports = exports;
