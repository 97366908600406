var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "ul",
    { staticClass: "search-keyword-list" },
    _vm._l(_vm.hotTrendKeywords, function (keyword) {
      return _c(
        "a",
        {
          key: keyword,
          staticClass: "search-keyword-list__cell",
          on: { click: () => _vm.searchByHotTrendKeyword(keyword) },
        },
        [
          _c("i", {
            staticClass:
              "search-keyword-list__icon jirafficon jirafficon-loupe",
          }),
          _vm._v(" "),
          _c("div", { staticClass: "search-keyword-list__keyword" }, [
            _vm._v("\n      " + _vm._s(keyword) + "\n    "),
          ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }