import FormGroup from '~/components/molecules/form/FormGroup.vue'
import FormControl from '~/components/molecules/form/FormControl.vue'
import FormLabel from '~/components/molecules/form/FormLabel.vue'
import FormInput from '~/components/molecules/form/FormInput.vue'
import FormDescription from '~/components/molecules/form/FormDescription.vue'
import ErrorLabel from '~/components/molecules/form/ErrorLabel.vue'
import CheckBox from '~/components/molecules/form/CheckBox.vue'
import RadioBox from '~/components/molecules/form/RadioBox.vue'
import SelectBox from '~/components/molecules/form/SelectBox.vue'
import TextArea from '~/components/molecules/form/TextArea.vue'
import FormSubmit from '~/components/molecules/form/FormSubmit.vue'
import FormModalSelectorBtn from '~/components/molecules/form/FormModalSelectorBtn.vue'
import VForm from '~/components/molecules/form/VForm.vue'
import AutocompletableSelectBox from '~/components/molecules/form/AutocompletableSelectBox.vue'

export default {
  FormGroup,
  FormLabel,
  FormInput,
  FormControl,
  FormDescription,
  ErrorLabel,
  CheckBox,
  SelectBox,
  TextArea,
  RadioBox,
  FormSubmit,
  FormModalSelectorBtn,
  VForm,
  AutocompletableSelectBox,
}
