import { Controller } from 'stimulus'

export default class AccordionController extends Controller {
  static targets = ['header', 'content', 'innerContent']

  headerTarget?: HTMLElement

  contentTarget?: HTMLElement

  innerContentTarget?: HTMLElement

  private isDisplayed = false

  updateShowStatus(flag: boolean): void {
    this.isDisplayed = flag
  }

  toggle(): void {
    if (!this.contentTarget) return
    if (!this.headerTarget) return

    if (this.isDisplayed) {
      this.contentTarget.style.height = '0'
      this.headerTarget.classList.remove('accordion__header--shown')
      this.contentTarget.classList.remove('accordion__contents--shown')
      this.updateShowStatus(false)
    } else {
      const innerHeight = this.innerContentTarget?.offsetHeight
      this.contentTarget.style.height = `${innerHeight}px`
      this.headerTarget.classList.add('accordion__header--shown')
      this.contentTarget.classList.add('accordion__contents--shown')
      this.updateShowStatus(true)
    }
  }
}
