import { Controller } from 'stimulus'
import Chart, {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
} from 'chart.js/auto'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import ModalController from '~/controllers/modal_controller'
import { findController } from '~/lib/stimulus'
import 'dayjs/locale/en'
import 'dayjs/locale/ja'
import 'chartjs-adapter-dayjs-4'

let tradeHistoryChart: Chart

interface ChartInputData {
  x: string
  y: number
}

interface ChartToolTipLabelContextData {
  parsed: {
    x: number
    y: number
  }
}

interface ChartConfig {
  type: ChartType
  options: ChartOptions
}

function noDataHandler(chartEmptyTxt: string) {
  return {
    id: 'noDataHandler',
    afterDatasetsDraw: (chart: Chart) => {
      const {
        ctx,
        data,
        chartArea: { top, left, width, height },
      } = chart
      ctx.save()

      if (data.datasets[0].data.length === 0) {
        ctx.font = 'bold 18px YuGothic'
        ctx.fillStyle = '#c7c7c7'
        ctx.textAlign = 'center'
        ctx.fillText(chartEmptyTxt, left + width / 2, top + height / 2)
      }
    },
  }
}
const alwayShowToolTipWhenHasnlyOneElement = {
  id: 'alwayShowToolTip',
  afterDraw: (chart: Chart) => {
    if (chart.data.datasets[0].data.length === 1) {
      if (!chart.tooltip?.getActiveElements().length) {
        chart.tooltip?.setActiveElements(
          [
            {
              datasetIndex: 0,
              index: 0,
            },
          ],
          {
            x: 0,
            y: 0,
          }
        )
      }

      if (!chart.getActiveElements().length) {
        chart.setActiveElements([
          {
            datasetIndex: 0,
            index: 0,
          },
        ])
        chart.update()
      }
    } else if (
      chart.data.datasets[0].data.length === 0 &&
      chart.tooltip?.getActiveElements().length
    ) {
      chart.tooltip?.setActiveElements([], { x: 0, y: 0 })
      chart.setActiveElements([])
      chart.update()
    }
  },
}

const configAxisesWhenEmptyData = (
  selectedData: ChartInputData[],
  chart: Chart
) => {
  const { scales } = chart.options
  if (!scales?.x || !scales?.y) return

  if (selectedData.length === 0) {
    scales.x.max = dayjs().subtract(1, 'day').format('L')
    scales.y.max = 40000
    scales.y.min = 0
  } else {
    scales.x.max = undefined
    scales.y.max = undefined
    scales.y.min = undefined
  }

  chart.update()
}

const config: ChartConfig = {
  type: 'line',
  options: {
    animation: {
      duration: 0,
    },
    hover: {
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        intersect: false,
        xAlign: 'center',
        yAlign: 'bottom',
        padding: 10,
        titleAlign: 'right',
        bodyAlign: 'right',
        backgroundColor: '#654597',
        caretPadding: 10,
        caretSize: 5,
        boxWidth: 0,
        boxHeight: 0,
        bodyFont: {
          weight: 'bold',
          size: 15,
        },
        callbacks: {
          label(context: ChartToolTipLabelContextData) {
            return new Intl.NumberFormat('ja', {
              style: 'currency',
              currency: 'JPY',
            }).format(context.parsed.y)
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'day',
          tooltipFormat: 'LL',
        },
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          maxTicksLimit: 6,
          callback(value: string | number) {
            return dayjs(value).format('MM/DD')
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        ticks: {
          maxTicksLimit: 6,
        },
      },
    },
  },
}

const momentFilterByDays = (
  data: ChartInputData[],
  amount: number,
  unit: dayjs.ManipulateType
) => {
  const dateInPast = dayjs().subtract(amount, unit).format('L')
  const dateNow = dayjs().add(1, 'day').format('L')

  return data.filter((item: ChartInputData) => {
    return dayjs(item.x).isBetween(dateInPast, dateNow)
  })
}

const processChartData = (datasets: string) => {
  if (typeof datasets === 'undefined') {
    return { dataAll: [], data1Week: [], data1Month: [], data3Months: [] }
  }

  const dataAll = JSON.parse(datasets.replace(/&quot;/gi, '"'))
  const data3Months = momentFilterByDays(dataAll, 3, 'months')
  const data1Month = momentFilterByDays(data3Months, 1, 'months')
  const data1Week = momentFilterByDays(data1Month, 1, 'weeks')

  return { dataAll, data1Week, data1Month, data3Months }
}

export default class extends Controller {
  static targets: string[] = [
    'modalContent',
    'chartData',
    'tradesHistoryChart',
    'timeFilterGroup',
  ]

  modalContentTarget?: HTMLElement

  chartDataTarget?: HTMLElement

  tradesHistoryChartTarget?: HTMLCanvasElement

  timeFilterGroupTarget?: HTMLElement

  showTradeModal(): void {
    const modal = findController<ModalController>('modal')
    if (!modal) return

    const modalContent = document.createElement('div')
    modalContent.innerHTML = this.modalContentTarget?.innerHTML || ''
    modal.show(modalContent.children[0])
  }

  connect(): void {
    const globalLocale: string =
      this.chartDataTarget?.dataset.globalLocale || 'ja'
    const chartData: string =
      this.chartDataTarget?.dataset.tradesDataJson || '[]'
    const chartEmptyTxt: string =
      this.chartDataTarget?.dataset.chartEmptyText || ''

    if (!(this.tradesHistoryChartTarget instanceof HTMLCanvasElement)) return

    dayjs.locale(globalLocale)
    dayjs.extend(isBetween)
    dayjs.extend(localizedFormat)

    const { dataAll, data1Week, data1Month, data3Months } =
      processChartData(chartData)

    const data: ChartData = {
      datasets: [
        {
          data: dataAll,
          borderColor: '#654597',
          pointBorderColor: '#654597',
          pointBackgroundColor: 'transparent',
          pointBorderWidth: 0,
          pointRadius: 5,
          pointHoverRadius: 7,
          pointHoverBorderWidth: 2,
        },
      ],
    }

    const chartConfig: ChartConfiguration = {
      ...config,
      data,
      plugins: [
        noDataHandler(chartEmptyTxt),
        alwayShowToolTipWhenHasnlyOneElement,
      ],
    }

    tradeHistoryChart = new Chart(this.tradesHistoryChartTarget, chartConfig)
    configAxisesWhenEmptyData(dataAll, tradeHistoryChart)

    const timeFilterItems = this.timeFilterGroupTarget?.querySelectorAll(
      '.trade-history__filter-group .trade-history__filter-item'
    )
    const inActiveAllFilterItems = () => {
      timeFilterItems?.forEach((filterItem) =>
        filterItem.classList.remove('-active')
      )
    }

    const changeChartData = (mode: string | undefined) => {
      let selectedData = []

      switch (mode) {
        case '1week':
          selectedData = data1Week
          break
        case '1month':
          selectedData = data1Month
          break
        case '3months':
          selectedData = data3Months
          break
        default:
          selectedData = dataAll
          break
      }

      configAxisesWhenEmptyData(selectedData, tradeHistoryChart)
      tradeHistoryChart.data.datasets[0].data = selectedData
      tradeHistoryChart.update()
    }

    timeFilterItems?.forEach((filterItem) => {
      filterItem.addEventListener('click', (event) => {
        inActiveAllFilterItems()

        if (event.currentTarget instanceof HTMLElement) {
          event?.currentTarget?.classList.add('-active')
          changeChartData(event?.currentTarget?.dataset.timeGroup)
        }
      })
    })
  }
}
