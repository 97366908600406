var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { class: "form__input--select-wrapper " + _vm.selectorWrapperClass },
    [
      _c(
        "select",
        {
          class:
            "form__input " + _vm.additionalClassName() + "" + _vm.selectorClass,
          attrs: { name: _vm.name, id: _vm.selectorId },
          domProps: { value: _vm.value },
          on: {
            input: _vm.updateValue,
            focus: function ($event) {
              return _vm.$emit("focus", $event)
            },
            blur: function ($event) {
              return _vm.$emit("blur", $event)
            },
          },
        },
        [_vm._t("default")],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }