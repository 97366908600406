
import Vue from 'vue'

export default Vue.extend({
  props: {
    messages: {
      type: Array,
      default: (): string[] => [],
    },
  },
})
