
import Vue from 'vue'
import vSelect from 'vue-select'

export default Vue.extend({
  components: {
    vSelect,
  },
  data() {
    return {
      inputSelected: null,
    }
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
    },
    noOption: {
      type: String,
      required: false,
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: [Object, Array],
    },
    disabled: {
      type: Boolean,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.inputSelected = this.selected
  },
  methods: {
    onSearch(search: string, loading: (arg: boolean) => void) {
      this.$emit('search', search, loading)
    },
    inputValue(option: { id: number | string; name: string }): void {
      this.$emit('input', option)
    },
  },
  watch: {
    selected(newVal) {
      this.inputSelected = newVal
    },
  },
})
