
import Vue from 'vue'

export default Vue.extend({
  name: 'CheckBox',
  props: {
    value: {},
    name: {
      type: String,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateValue(e: Event): void {
      function toBoolean(data: string): boolean {
        return data.toLowerCase() === 'true'
      }

      const { target } = e
      if (!target || !(target instanceof HTMLInputElement)) {
        throw new TypeError()
      }
      const checked =
        typeof target.checked === 'boolean'
          ? target.checked
          : toBoolean(target.value)
      this.$emit('input', checked)
      this.$emit('change', checked)
    },
  },
})
