import { Application, Controller } from 'stimulus'

export default function findStimulusController<R extends Controller>(
  name: string,
  app: Application
): R | undefined {
  const controllerTypeCheck = (controller: Controller): controller is R => {
    return controller.identifier === name
  }

  const controller = app.controllers.find((c) => c.identifier === name)

  if (!controller) return undefined
  if (!controllerTypeCheck(controller)) return undefined

  return controller
}
