import { Controller } from 'stimulus'
import { execCopy } from '~/core/execCopy'
import { showSnackBar } from '~/core/snackBar'

export default class extends Controller {
  static targets = ['source', 'button']

  sourceTarget?: HTMLInputElement

  sourceTargets?: Array<HTMLInputElement>

  buttonTarget?: Element

  buttonTargets?: Array<Element>

  copy(): void {
    const source = this.data.get('source')
    const succeedLabel = this.data.get('succeedLabel')

    if (
      typeof source !== 'string' ||
      !this.buttonTarget ||
      !this.buttonTargets
    ) {
      throw new TypeError()
    }
    if (execCopy(source)) {
      showSnackBar({
        title: succeedLabel || 'URLをコピーしました',
        variant: 'success',
        icon: 'check-circle-filled',
      })
    }
  }

  copyWithInput(): void {
    if (
      !this.sourceTarget ||
      !this.sourceTargets ||
      !this.buttonTarget ||
      !this.buttonTargets
    ) {
      return
    }

    const oldContentEditable = this.sourceTarget.contentEditable
    const oldReadOnly = this.sourceTarget.readOnly

    this.sourceTarget.contentEditable = 'true'
    this.sourceTarget.readOnly = false
    this.sourceTarget.select()
    document.execCommand('copy')

    this.sourceTarget.blur()
    this.sourceTarget.contentEditable = oldContentEditable
    this.sourceTarget.readOnly = oldReadOnly
    this.buttonTarget.innerHTML = 'copied!'
    this.buttonTarget.classList.toggle('btn-primary')
  }
}
