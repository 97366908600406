import axios, { AxiosError, AxiosRequestConfig } from 'axios'
import AlertController from '~/controllers/alert_controller'
import { findController } from '~/lib/stimulus'

/* デフォルト設定 */
const instance = axios.create({
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    'X-Requested-With': 'XMLHttpRequest',
  },
  responseType: 'json',
})

export interface AxiosRequestConfigWithAdditionals extends AxiosRequestConfig {
  errorHandle?: boolean
}

export interface ErrorResponseBody {
  errors: string[]
}

function isAxiosRequestConfigWithAdditionals(
  config: unknown
): config is AxiosRequestConfigWithAdditionals {
  return !!(config instanceof Object)
}

function isErrorResponseBody(body: unknown): body is ErrorResponseBody {
  return (
    body instanceof Object &&
    Object.prototype.hasOwnProperty.call(body, 'errors')
  )
}

const UNEXPECTED_ERROR_MESSAGE = '予期せぬエラーが発生しました'
function errorResponseHandler<T>(
  error: AxiosError<T>
): Promise<AxiosError<T>> | void {
  const alert = findController<AlertController>('alert')
  if (!alert) throw new Error()
  if (
    !isAxiosRequestConfigWithAdditionals(error.config) ||
    error.config.errorHandle === false
  ) {
    return Promise.reject(error)
  }

  if (error.response && isErrorResponseBody(error.response.data)) {
    const { errors } = error.response.data
    const message = errors ? errors.join('\n') : UNEXPECTED_ERROR_MESSAGE
    alert.show({
      title: message,
    })
    return Promise.reject(error)
  }

  alert.show({
    title: UNEXPECTED_ERROR_MESSAGE,
  })
  return Promise.reject(error)
}

// apply interceptor on response
instance.interceptors.response.use((response) => response, errorResponseHandler)
export default instance
