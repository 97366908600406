
import Vue from 'vue'
import vSelect from 'vue-select'

export default Vue.extend({
  components: {
    vSelect,
  },
  data() {
    return {
      input: {
        selected: [],
      },
    }
  },
  props: {
    error: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    taggable: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selected: {
      type: Array,
    },
    selectorId: {
      type: String,
    },
    selectorClass: {
      type: String,
    },
    selectorWrapperClass: {
      type: String,
    },
  },
  mounted() {
    this.input.selected = this.$props.selected
  },
  methods: {
    updateValue(value: Object | String): void {
      this.$emit('input', value)
      this.$emit('change', value)
    },
    additionalClassName(): string {
      return this.$props.error ? 'form__input--field-error' : ''
    },
  },
})
