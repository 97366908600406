import { Controller } from 'stimulus'

export default class BlogsPriceSettingController extends Controller {
  static targets = ['priceForm', 'formGroup']

  priceFormTarget!: HTMLInputElement

  formGroupTarget!: HTMLElement

  private currentPrice = BlogsPriceSettingController.DEFAULT_PRICE

  private static readonly DEFAULT_PRICE: number = 0

  initialize(): void {
    if (
      !this.priceFormTarget ||
      Number(this.priceFormTarget.value) >
        BlogsPriceSettingController.DEFAULT_PRICE
    ) {
      this.currentPrice = Number(this.priceFormTarget.value)
    } else {
      this.currentPrice = BlogsPriceSettingController.DEFAULT_PRICE
    }
  }

  setPrice(): void {
    if (
      !this.formGroupTarget ||
      !this.priceFormTarget ||
      !(this.formGroupTarget instanceof HTMLElement)
    ) {
      return
    }

    this.priceFormTarget.value = String(this.currentPrice)
    this.formGroupTarget.classList.remove('is-hidden')
  }

  setFree(): void {
    if (
      !this.formGroupTarget ||
      !this.priceFormTarget ||
      !(this.formGroupTarget instanceof HTMLElement)
    ) {
      return
    }

    if (Number(this.priceFormTarget.value) > 0) {
      this.currentPrice = Number(this.priceFormTarget.value)
    }

    this.priceFormTarget.value = String(
      BlogsPriceSettingController.DEFAULT_PRICE
    )
    this.formGroupTarget.classList.add('is-hidden')
  }
}
