import { AxiosResponse } from 'axios'
import axios from '~/lib/axios'
import { csrfToken } from '~/core/vars'

export const postMypageOripaLotteryResultPrizesConvert = (
  lotteryResultPrizeId: number
): Promise<
  AxiosResponse<Api.Oripa.LotteryResultPrizes.Convert.Post.Response>
> => {
  const token = csrfToken()
  if (!token) throw TypeError('null csrf token')

  const params = new FormData()
  params.append('authenticity_token', token)

  return axios.post(
    `/mypage/oripa/lottery_result_prizes/${lotteryResultPrizeId}/convert`,
    params
  )
}
