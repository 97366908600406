import Vue from 'vue'
import { findController } from '~/lib/stimulus'
import ModalController from '~/controllers/modal_controller'

export default function (
  ComponentClass: typeof Vue,
  variant: Modal.Variants = 'fixed-size',
  options: Vue.ComponentOptions<Vue> = {}
): void {
  const modal = findController<ModalController>('modal')
  if (!modal) return
  modal.setVariant(variant)
  modal.showWithVue(ComponentClass, options)
}
